.cp-flex-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.cp-overflow-hidden {
  overflow: hidden;
}

.cp-flex-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
  align-items: center;
  &.space-around {
    justify-content: space-around;
  }
  &.center {
    justify-content: center;
  }
  &.space-between {
    justify-content: space-between;
  }
  @for $i from 1 through 4 {
    &.gap-#{$i} {
      gap: #{$i * 8}px;
    }
  }
}

@for $i from 1 through 4 {
  .cp-w-#{$i * 25} {
    width: #{$i * 25 + "%"};
  }
}
.cp-w-content {
  width: fit-content;
}

.cp-h-100 {
  height: 100%;
}

// Flex Box

.display-flex {
  display: flex;
  &.flex-wrap {
    flex-wrap: wrap;
  }
  &.flex-column {
    flex-direction: column;
  }
  &.center {
    justify-content: center;
    align-items: center;
  }
  &.justify-content-start {
    justify-content: flex-start;
  }
  &.justify-content-between {
    justify-content: space-between;
  }
  &.justify-content-around {
    justify-content: space-around;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-start {
    align-items: flex-start;
  }
  &.align-items-center {
    align-items: center;
  }
  &.align-items-end {
    align-items: center;
  }
  &.align-items-baseline {
    align-items: baseline;
  }
  &.align-self-center {
    align-self: center;
  }
  .align-self-start {
    align-self: flex-start;
  }
  .align-self-end {
    align-self: flex-end;
  }
  @for $i from 1 through 15 {
    &.gap-#{$i} {
      gap: #{$i * 8}px;
    }
  }
}

@for $i from 1 through 15 {
  .cp-gap-#{$i} {
    gap: #{$i * 8}px;
  }
}
