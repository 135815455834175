.cp-select {
  @extend %cp-input-properties;
  display: inline-block;
  vertical-align: top;
  background: white url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") no-repeat right 0.5rem center;
  background-size: 8px 10px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 1.5rem !important;

  &:disabled {
    background-color: #cccccc !important;
    color: #666666 !important;
    cursor: not-allowed !important;
  }

  &--covid {
    option {
      background-color: white;
      color: black;
      &:first-child {
        color: $cp-disabled;
      }
    }

    &__suspected {
      background-color: $cp-watcher;
    }

    &__positive {
      background-color: $cp-unstable;
      color: white;
    }
  }
}
