@import "../../abstracts/color";

// removes:  mat default underline
.mat-form-field .mat-form-field-underline {
  display: none;
}

/**
* enables:  font color-black and size 14px
* removes:  blurriness of font
**/
.cp-light-theme .mat-form-field .mat-form-field-label {
  color: black;
  white-space: normal;
  padding: 1px;
  font-size: 14px;

  // remove's label blur and scale's it to font-size 14px
  transform: translateY(-1.28125em) scale(1) perspective(0) translateZ(0) !important;
  -ms-transform: translateY(-1.28125em) scale(1) perspective(0) translateZ(0) !important;
  backface-visibility: hidden;
  width: 100% !important;
}

mat-form-field {
  display: inline-block;
}

/**
* enables:  font color-black on focus
* removes:  label from inheriting the theme color green
**/
.cp-light-theme .mat-form-field.mat-focused .mat-form-field-label {
  color: black;
}

// enables:  font color-red on error
.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: red !important;
}

// enables:  border color red
.mat-form-field .ng-invalid.ng-touched .cp-mat-custom-input,
.mat-form-field .mat-select-invalid,
.mat-form-field.mat-form-field-invalid .mat-input-element,
.mat-form-field.mat-form-field-invalid .mat-select {
  border-color: red !important;
}

// removes: margin so the error message is closer to the input
.mat-form-field .mat-form-field-subscript-wrapper {
  margin-top: 0;
}

/**
* enables:  font-size 14, grey border, on focus border-color green
**/
.mat-form-field .cp-mat-custom-input,
.mat-input-element.cp-form-input,
.mat-form-field .mat-select,
.mat-form-field input,
.mat-form-field textarea {
  font-size: 14px;

  margin-top: 8px;
  padding: 8px;
  border-radius: 2px;
  border: 1px solid map-get($map: $text, $key: 200);
  box-sizing: border-box;

  &--focus,
  &:focus {
    border-color: map-get($primary, 500);
  }
  &:-moz-input-placeholder {
    color: lightgray;
  }
  &::-webkit-input-placeholder {
    color: lightgray;
  }
}

.orders-input-comp .mat-input-element,
.orderable-preset-form .mat-input-element {
  box-sizing: border-box;
}

// To fix the select overflow issue - START
.mat-select-panel mat-option.mat-option {
  height: unset;
}

.mat-option-text.mat-option-text {
  white-space: nowrap;
  line-height: 35px;
}
// To fix the select overflow issue - END

// enables: larger size for label name
.large-label .mat-form-field-infix {
  padding-top: 24px;
}

// enables: grey placeholder for mat-select
.mat-select-placeholder {
  color: lightgray;
}

// .multiple-line {
//   .mat-select-panel mat-option.mat-option {
//     height: unset;
//     line-height: 2em;
//     border-bottom: 1px solid #ccc;
//   }

//   .mat-option-text.mat-option-text {
//     white-space: normal;
//   }
// }

// Input with no padding for angular material form field
// This won't show the error correclty but it can allign input with buttons
mat-form-field.cp-input-mat {
  &--no-label {
    & .mat-form-field-infix {
      border: 0;
    }
    & .mat-select {
      margin: 0;
    }
  }

  & .mat-form-field-wrapper {
    padding: 0rem;
  }

  & .mat-form-field-infix {
    padding: 0rem;
  }

  & .mat-form-field-prefix {
    top: 0px;
    position: absolute;
    transform: translateY(1.4rem);
    z-index: 1;
    height: calc(100% - 1.4rem);
    display: flex;
    align-items: center;
    left: 0.2rem;
  }
  & .date-field {
    padding-left: 2.4rem;
  }
  & .calenderIcon .mat-focus-indicator {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 1.2rem;
  }
}
// Normal mat checkbox on expandable
.cp-expandable-checkbox.mat-checkbox,
.cp-mat-checkbox.mat-checkbox {
  & .mat-checkbox-layout {
    margin: 0px;
    & .mat-checkbox-inner-container {
      margin-top: 4px;
    }
  }
}

// Multi select input Checkbox styling

.cp-multiselect-checkbox {
  width: 100%;
  margin-bottom: -6px;
  & label.mat-checkbox-layout {
    width: 100%;
    margin: 0;
    align-items: center;
    gap: 0.5rem;
    & .mat-checkbox-inner-container {
      margin: 0;
    }

    .mat-checkbox-label {
      max-width: calc(100% - 16px - 0.5rem);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .mat-checkbox-label .active-text {
      color: map-get($map: $primary, $key: 500);
    }
  }
}

// mat-chips

.cp-mat-chips {
  & .cp-mat-chips__label {
    height: 1.2857rem;
    margin-bottom: 0.5714rem;
  }
  & .mat-form-field-appearance-standard .mat-form-field-flex {
    padding: 0px;
  }
  & .mat-form-field-infix {
    width: 100%;
    border: 1px solid map-get($map: $text, $key: 200);
    min-height: 2.41rem;
    border-radius: 2px;
    display: flex;
    align-items: center;
  }
  & .mat-chip-list {
    width: 100%;
  }
  & .mat-form-field {
    width: 100%;
  }
  & .mat-chip-list-wrapper {
    display: flex;
    width: 100%;
    padding-left: 8px;
    gap: 0.4rem;
  }
  & &__chip.mat-chip {
    min-height: 1.5rem;
    border-radius: 1px;
    background: transparent;
    border: 1px solid #e0e0e0;
    color: map-get($map: $text, $key: 400);
    padding: 0.1rem 0.3rem;
    height: auto;
    margin: 0;
    max-width: 21rem;
    overflow: hidden;
  }
  & input.chip-input.mat-chip-input {
    border: none;
    height: 1px;
    margin: 0;
  }
  & .mat-icon.mat-chip-remove.cp-mat-chips__button {
    color: map-get($map: $text, $key: 400);
    opacity: 1;
  }
  & &__chip__content {
    max-width: 16rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

.cp-mat-search-dropdown {
  width: 20rem;
  margin: 25px 0 0 8px !important;
}

// material dropdown arrow does not show active on selection
.mat-form-field.mat-focused.mat-primary .cp-custom-mat-input .mat-select {
  &:not(&:focus) .mat-select-arrow {
    color: rgba(0, 0, 0, 0.54);
  }
}

// Select panel dropdown pannel class
.mat-select-panel {
  &.cp-ncp-dropdown {
    min-width: calc(100% + 18px) !important;
    margin: 26px 0 0 30px !important;
  }
  &.cp-mar-dropdown {
    margin: 25px 0 0 7px !important;
    width: 6.8rem;
  }
  &.cp-order-dropdown {
    margin: 31px 0 0 0px !important;
    width: 100%;
  }
}

// Search dropdown
.cp-mat-search-dropdown-container {
  & .cp-mar-select-input {
    background-color: #f6f7f9;
    color: #677797;
    border-radius: 0px 2px 2px 0px;
  }
}

// Autocomplete dropdown
.cp-mat-autocomplete-dropdown-container {
  & .cp-mat-chips .mat-chip-list-wrapper {
    margin: 0;
    padding: 0;
    gap: 0;
  }
}
