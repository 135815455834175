@import "@angular/material/theming";
@import "../abstracts/variable";

// custom cp fonts
$cp-typography: mat-typography-config(
  $font-family: $font-family-base,
);

@include mat-core($cp-typography);

$mat-theme-ignore-duplication-warnings: true;

/*=============================================================================		
  															Light Theme 
=============================================================================*/

$cp-light-theme-foreground: (
  base: $cp-black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba(black, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: $cp-black,
  hint-text: $dark-disabled-text,
  progress-status: map-get($green, 300),
  secondary-text: $dark-secondary-text,
  icon: rgba(black, 0.54),
  icons: rgba(black, 0.54),
  text: rgba(black, 0.87),
  slider-min: rgba(black, 0.87),
  slider-off: rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

$cp-light-theme-background: (
  status-bar: map-get($cool-grey, 100),
  app-bar: map-get($mat-grey, 100),
  background: map-get($mat-grey, 50),
  hover: rgba(black, 0.04),
  card: white,
  dialog: white,
  disabled-button: #dddfe2,
  raised-button: white,
  focused-button: $dark-focused,
  selected-button: map-get($mat-grey, 300),
  selected-disabled-button: map-get($mat-grey, 400),
  disabled-button-toggle: map-get($mat-grey, 200),
  unselected-chip: map-get($mat-grey, 300),
  disabled-list-option: map-get($mat-grey, 200),
  tooltip: map-get($mat-grey, 700),
);

@function create-cp-light-theme($primary, $accent, $warn) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $cp-light-theme-foreground,
    background: $cp-light-theme-background
  );
}

$cp-light-theme: create-cp-light-theme(
  mat-palette($primary),
  mat-palette($primary),
  mat-palette($mat-red)
);

.cp-light-theme {
  @include angular-material-theme($cp-light-theme);
}

/*=============================================================================		
  															Dark Theme 
=============================================================================*/

$cp-dark-theme-foreground: (
  base: white,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba(white, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-secondary-text,
  icon: white,
  icons: white,
  text: white,
  slider-min: white,
  slider-off: rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);

$cp-dark-theme-background: (
  status-bar: black,
  app-bar: map-get($mat-grey, 900),
  background: #303030,
  hover: rgba(white, 0.04),
  card: map-get($mat-grey, 800),
  dialog: map-get($mat-grey, 800),
  disabled-button: rgba(white, 0.12),
  raised-button: map-get($mat-grey, 800),
  focused-button: $light-focused,
  selected-button: map-get($mat-grey, 900),
  selected-disabled-button: map-get($mat-grey, 800),
  disabled-button-toggle: black,
  unselected-chip: map-get($mat-grey, 700),
  disabled-list-option: black,
  tooltip: map-get($mat-grey, 700),
);

@function create-cp-dark-theme($primary, $accent, $warn) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: true,
    foreground: $cp-dark-theme-foreground,
    background: $cp-dark-theme-background
  );
}

$cp-dark-theme: create-cp-dark-theme(
  mat-palette($primary),
  mat-palette($primary),
  mat-palette($mat-red)
);

.cp-dark-theme {
  @include angular-material-theme($cp-dark-theme);
}

@mixin cp-light-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  // Use mat-color to extract individual colors from a palette as necessary.

  .avatar {
    .initials {
      color: mat-color($primary, 500);
    }
  }

  .cp-progress-bar {
    .cp-progress-container {
      background-color: mat-color($background, status-bar);
    }
  }
}

@include cp-light-theme($cp-light-theme);
