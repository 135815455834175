.cp-btn {
    border: none;
    display: inline-block;
    vertical-align: top;
    // font-weight: 400;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.25rem 0.5rem !important;
    font-size: $cp-fs-btn !important;
    line-height: 1.5 !important;
    border-radius: 0.2rem !important;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none !important;

    &__icon {
        background-color: transparent;

        &:focus {
            outline: none;
        }

        &--success {
            color: $cp-m-green;
        }

        &:disabled {
            background-color: transparent !important;
        }
    }

    &--danger {
        background: $cp-mp-red !important;
        color: white;
        
        &:hover {
            background: darken($color: $cp-mp-red, $amount: 15%) !important;
        }
    
        &:hover:disabled {
            background-color: #cccccc !important;
        }
    }

    &--code-blue {
        background-color: $cp-code-blue;
        color: white;
    }
    
    &__outline {
        background-color: transparent;
        
        &:focus {
            outline: none;
        }
        
        &--primary {
            border: 1px solid $cp-primary;
            color: $cp-primary;
            
            &:hover {
                color: white;
                background-color: $cp-primary;
            }
        }
    }
    
    &--primary {
        background-color: $cp-primary;
        color: white;
    
        &:hover {
            background: darken($color: $cp-primary, $amount: 15%) !important;
        }
        
        &:focus {
            outline: none;
        }
    }
}

@mixin cp-new-disabled-btn {
    color: white;
    background-color: $cp-m-grey-1;
    border: 2px solid $cp-m-grey-1;
}

.cp-new-btn {
    outline: none !important;
    border-radius: 3px;
    padding: 3px 20px;
    font-weight: bold;
    border: none;
    
    &:active {
        border: none;
    }
    
    &:focus {
        box-shadow: none;
    }
    
    // Main Buttons
    &__primary {
        background-color: $cp-primary;
        color: white;
        border: 2px solid $cp-primary;
        
        &:active {
            border: 2px solid $cp-primary;
        }
        
        &:disabled {
            @include cp-new-disabled-btn;
        }
    }
    
    &__danger {
        background-color: $cp-m-red;
        color: white;
        border: 2px solid $cp-m-red;
        
        &:active {
            border: 2px solid $cp-m-red;
        }
        
        &:disabled {
            @include cp-new-disabled-btn;
        }
    }
    
    // Outline Buttons
    &__outline-primary {
        background-color: transparent;
        color: $cp-primary;
        border: 2px solid $cp-primary;
        
        &:active {
            border: 2px solid $cp-primary;
        }
        
        &:hover {
            color: white;
            background-color: $cp-primary;
        }
        
        &:focus {
            outline: none;
        }
    }
    
    &__outline-secondary {
        background-color: transparent;
        color: $cp-m-grey-dark;
        border: 2px solid $cp-m-grey-dark;
        
        &:active {
            border: 2px solid $cp-m-grey-dark;
        }
    }
    
    &__outline-danger {
        background-color: transparent;
        color: $cp-m-red;
        border: 2px solid $cp-m-red;
        
        &:active {
            border: 2px solid $cp-m-red;
        }
    }
}
