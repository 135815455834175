%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  @extend %flex-center;
  font-size: $font-size-c1;
  margin: 0 0.5rem;
}

.title-font {
  font-weight: 500;
}
.normal-font {
  font-weight: 400;
}

.policy {
  color: map-get($primary, "contrast", 700);
  text-decoration: underline;

  &:hover {
    color: map-get($primary, "contrast", 700);
  }

  &-container {
    padding: 2% 10%;
  }
}

.primary {
  color: map-get($primary, 600);

  &-bg {
    background-color: map-get($primary, 500) !important;

    &-circle {
      background-color: map-get($primary, 500) !important;
      color: white;
      @extend %circle;
    }

    &-light {
      background-color: map-get($primary, 100) !important;
    }

    &-light-2 {
      background-color: map-get($primary-new, 100) !important;
    }
    &-table {
      background-color: #b1e2e2 !important;
    }
  }
}

.light {
  &-bg {
    &-circle {
      background-color: map-get($cool-grey, 200);
      color: map-get($map: $bg, $key: 800);
      @extend %circle;
    }
  }
}

.highlight {
  color: map-get($map: $primary, $key: 400);
}

.success-text {
  color: map-get($green, 400);
}

.error-text {
  color: map-get($error, 400);
}
.error-bg {
  background-color: map-get($map: $error, $key: 200);
}

.cp-cursor-pointer {
  cursor: pointer;
}

.cp-white {
  color: $cp-white;
}

.cp-black {
  color: $cp-black;
}

.bg {
  &-light {
    background-color: map-get($bg, 100) !important;
  }

  &-light-2 {
    background-color: map-get($bg, 200) !important;
  }

  &-white {
    background-color: map-get($bg, 000) !important;
  }

  &-dark {
    background-color: map-get($bg, 800) !important;
  }

  &-error {
    background-color: map-get($error, 300);
  }
}

.inline {
  display: inline-block;
  vertical-align: top;

  &-margin {
    &-right {
      margin-right: 8px;
    }
    &-left {
      margin-left: 8px;
    }
  }
}

.sub-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.15px;
  margin-left: 6px;
  margin-bottom: 0.57143rem;
}

.cp-badge {
  padding: 2px 8px;
  border-radius: 2px;
  margin: 4px;
  font-size: 10px;
  line-height: 16px;

  &-danger {
    background-color: map-get($map: $error, $key: 300);
    color: map-get($map: $error, $key: 500);
    font-weight: 550;
    font-size: 12px !important;
    padding: 4px 8px;
    border-radius: 2px;
  }
}

.message {
  display: flex !important;
  align-items: center !important;
  width: 100%;
  padding: 0.5rem 0;
  font-size: $font-size-c2;
  color: map-get($map: $text, $key: 300);
  .custom-icon-size {
    font-size: $font-size-c3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.no_data_found_block {
  font-size: 5rem;
  color: #ccc;
  opacity: 0.5;
  text-align: center;

  .additional_info {
    font-size: 2rem;
  }

  &.sm {
    font-size: 14px;
    color: map-get($map: $alpha-grey, $key: 300);
  }
}

// Text allignment
.text-center {
  text-align: center;
}

.iris-modal-container {
  background: #ffffff;
  overflow-y: auto;
  min-width: 30vw;

  .title {
    display: flex;
    font-weight: 600;
    margin-bottom: 22px;
    color: map-get($map: $text, $key: 600);
    font-size: $font-size-h3;

    .mat-icon {
      margin-right: 8px;
      color: map-get($map: $primary, $key: 500);
    }
  }

  .content {
    margin-bottom: 24px;
    color: map-get($map: $text, $key: 500);
  }

  &.form-container {
    width: 650px;
  }

  .form-title {
    padding: 16px;
    font-weight: 700;
    font-size: $font-size-base;
    background-color: map-get($map: $bg, $key: 100);
    color: black;
    text-align: center;
  }

  .form-body {
    padding: 24px 16px;
    border-bottom: 1px solid map-get($map: $primary, $key: 500);
  }

  .form-body-overflow {
    padding: 24px 16px;
    overflow: auto;
    max-height: 80vh;
  }

  .form-footer {
    padding: 1rem 0.5rem;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0px -0.5px 2px rgba(0, 0, 0, 0.25);
    gap: 0.5rem;
  }

  .mat-dialog-actions {
    padding: 16px 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.iris-error {
  color: map-get($map: $error, $key: 500);
  font-size: 12px;
  margin-top: 8px;
  // text-transform: capitalize;
}
//

@for $i from 1 through 3 {
  .pb-#{$i} {
    padding: #{$i * 8}px;
  }
}

.ml {
  margin-left: 8px !important;
}

.mr {
  margin-right: 8px !important;
}

.mt {
  margin-top: 8px !important;
}

.mb {
  margin-bottom: 8px !important;
}

.margin-auto {
  margin: 0 auto;
}

// scrollbar classes

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb:focus {
  background-color: map-get($map: $bg, $key: 500) !important;
}

::-webkit-scrollbar-thumb {
  background-color: map-get($map: $cool-grey, $key: 200) !important;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: map-get($map: $bg, $key: 500) !important;
}

$dateTimeRowHeight: 115px;
.date-time-row {
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 2px 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px;
  background-color: white;
  height: $dateTimeRowHeight;
  z-index: +5;

  .warning-holder {
    position: absolute;
    top: 0;
  }

  .content {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .b-holder {
      display: flex;
    }
  }
}

// Background colors
.background {
  &-success {
    background-color: green;
    color: white;
  }
  &-warning {
    background-color: #ffd700;
  }
  &-danger {
    background-color: red;
    color: white;
  }
  &-light {
    background-color: map-get($bg, 200);
    color: map-get($text, 400);
  }
}

// Text colors
.text {
  &-success {
    color: map-get($green, 400) !important;
  }
  &-warning {
    color: map-get($yellow, 400) !important;
  }
  &-danger {
    color: map-get($map: $error, $key: 400) !important;
  }
  &-orange-400 {
    color: map-get($orange, 400);
  }
}

.hide_this {
  display: none !important;
}

.patient-header-comp,
.pat-list-comp {
  .standard-prec,
  .airborne-prec,
  .contact-prec,
  .droplet-prec,
  .isolation {
    font-weight: 600;
    color: white;
    text-align: center;
    background-color: map-get($map: $primary, $key: 400);
  }

  .standard-prec {
    background-color: map-get($map: $yellow, $key: 400);
  }

  .contact-prec {
    background-color: map-get($map: $orange, $key: 400);
  }

  .airborne-prec {
    background-color: map-get($map: $blue, $key: 400);
  }

  .droplet-prec {
    background-color: map-get($map: $green, $key: 400);
  }
}

.order-select {
  background-color: map-get($map: $text, $key: 100) !important;
  border: 1px solid map-get($map: $bg, $key: 400) !important;
  padding: 0.7rem 1rem !important;
}

.order-input {
  border: 1px solid map-get($map: $bg, $key: 400) !important;
  padding: 0.75rem 1rem !important;
  background-color: map-get($map: $bg, $key: 000) !important;
}

.order-label {
  // background-color: map-get($map: $bg, $key: 200);
  padding-top: 0.5rem;
  margin: 1rem 0 0.5rem;
  border-right: 2px;
  font-weight: 600;
  color: map-get($map: $text, $key: 500);
  // letter-spacing: 1.5px;
  width: 100%;
}

//Utilities

.width--100 {
  width: 100%;
}

// Flex Box

.display-flex {
  display: flex;
  &.flex-wrap {
    flex-wrap: wrap;
  }
  &.flex-column {
    flex-direction: column;
  }
  &.center {
    justify-content: center;
    align-items: center;
  }
  &.justify-content-start {
    justify-content: flex-start;
  }
  &.justify-content-between {
    justify-content: space-between;
  }
  &.justify-content-around {
    justify-content: space-between;
  }
  &.justify-content-end {
    justify-content: flex-end;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.align-items-start {
    align-items: flex-start;
  }
  &.align-items-center {
    align-items: center;
  }
  &.align-items-end {
    align-items: flex-end;
  }
  &.align-items-baseline {
    align-items: baseline;
  }
  &.align-self-start {
    align-self: flex-start;
  }
  &.align-self-center {
    align-self: center;
  }
  &.align-self-end {
    align-self: flex-end;
  }
  &.align-self-baseline {
    align-self: baseline;
  }
  &.align-self-center {
    align-self: center;
  }
  & .flex-1 {
    flex: 1;
  }
  @for $i from 1 through 3 {
    &.flex-gap-#{$i} {
      gap: #{$i * 8}px;
    }
  }

  // Flex Items
}

.c--pointer {
  cursor: pointer;
}

// Margin and padding
.pad--0 {
  padding: 0rem;
}

.cp-clr-danger {
  background-color: map-get($map: $error, $key: 300) !important;
  color: map-get($map: $error, $key: 500) !important;
}

.cp-clr-discharged {
  background-color: map-get($map: $error, $key: 100) !important;
}

.cp-clr-re-admitted {
  background-color: map-get($map: $orange, $key: 100) !important;
}

.text-overflow-ellipses {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 100%;
}
// Text overflow

.cp-text-overflow {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  &--line-2 {
    -webkit-line-clamp: 2;
  }
}

.cp-text-overflow-ellipses {
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
}

a.cp-text-decoration-none {
  text-decoration: none;
}

.cp-no-margin-para p {
  margin: 0;
}

// Icon color
.cp-icon,
.mat-icon-button.cp-icon {
  color: map-get($map: $text, $key: 300);
  &--hover:hover {
    color: map-get($map: $primary, $key: 500);
  }
  &--active {
    color: map-get($map: $primary, $key: 500);
  }
  &--primary {
    color: map-get($map: $primary, $key: 600);
    &.cp-icon--hover:hover {
      color: map-get($map: $primary, $key: 500);
    }
  }
}

// Print hide
@media print {
  .hide-on-print {
    display: none !important;
  }
}

.cp-highlight-text {
  padding: 0px;
  background-color: map-get($bg, 400);
}
