.np-accordion {
  .card-header {
    padding: 0;
  }

  .card-body {
    overflow-y: auto;
    max-height: 30rem;
  }
}
