.iris-badge {
  padding: 2px 8px;
  border-radius: 2px;
  margin: 4px;
  font-size: 10px;
  line-height: 16px;

  &-danger {
    background-color: map-get($map: $error, $key: 300);
    color: map-get($map: $error, $key: 500);
    font-weight: 550;
    font-size: 12px !important;
    padding: 4px 8px;
    border-radius: 2px;
    width: 100%;
    justify-content: center;
    display: flex;
  }

  &-medium {
    background-color: map-get($map: $yellow, $key: 200);
    color: map-get($map: $orange, $key: 500);
    font-weight: 550;
    font-size: 12px !important;
    padding: 4px 8px;
    border-radius: 2px;
    width: 100%;
    justify-content: center;
    display: flex;
  }

  &-new {
    background-color: map-get($map: $blue, $key: 200);
    color: map-get($map: $blue, $key: 500);
    font-weight: 550;
    font-size: 12px !important;
    padding: 4px 8px;
    border-radius: 2px;
    width: 100%;
    justify-content: center;
    display: flex;
  }

  &-active {
    background-color: map-get($map: $primary, $key: 200);
    color: map-get($map: $primary, $key: 500);
    font-weight: 550;
    font-size: 12px !important;
    padding: 4px 8px;
    border-radius: 2px;
    width: 100%;
    justify-content: center;
    display: flex;
  }

  &-low,
  &-inactive {
    background-color: transparent;
    color: map-get($map: $text, $key: 300);
    font-weight: 550;
    font-size: 12px !important;
    padding: 3px 7px;
    border-radius: 2px;
    width: 100%;
    border: 1px solid map-get($map: $text, $key: 300);
    justify-content: center;
    display: flex;
  }
}

.iris-badge-rounded {
  border-radius: 15px;
  padding: 2px 6px 2px 6px;
  font-weight: 500;
  &--primary {
    color: map-get($map: $primary-new, $key: 500);
    background-color: map-get($map: $primary-new, $key: 200);
  }
  &--blue {
    color: map-get($map: $blue, $key: 500);
    background-color: map-get($map: $blue, $key: 300);
  }
}

.iris-label-default {
  display: flex;
  border-radius: 2px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

.iris-label-default.small {
  padding: 2px 8px;
  height: 20px;
  .icon {
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
    margin-left: 4px;
  }
}

.iris-label-default.medium {
  padding: 4px 8px;
  height: 25px;
  .icon {
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 8px;
    margin-left: 8px;
  }
}

.iris-label-default.large {
  padding: 4px 8px;
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    vertical-align: middle;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 8px;
    margin-left: 8px;
  }
}

.cp-blue {
  background-color: #34a2b1;
}
.cp-grey {
  background-color: #596782;
}

.iris-label-outline {
  display: flex;
  height: 32px !important;
  border-radius: 2px;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  // font-size: 10px;
  // line-height: 16px;
  // margin-right: 2vh;
  // margin-top: 1vh;
  width: 100%;
  .icon {
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
    margin-left: 4px;
  }
}

.label-left {
  flex-direction: row-reverse;
}

.iris-label-outline.label-red {
  color: #eb4049;
  border: 1px solid currentColor;
  box-sizing: border-box;
}

.iris-label-default.label-red {
  border: #eb4049;
  box-sizing: border-box;
  background-color: #eb4049;
}

.iris-label-outline.label-blue {
  color: #488af8;
  border: 1px solid currentColor;
  box-sizing: border-box;
}

.iris-label-default.label-blue {
  border: #488af8;
  box-sizing: border-box;
  background-color: #488af8;
}

.iris-label-default.cp-blue {
  border: #34a2b1;
  box-sizing: border-box;
  background-color: #34a2b1;
}

.iris-label-outline.label-green {
  color: #28a677;
  border: 1px solid currentColor;
  box-sizing: border-box;
}

.iris-label-default.label-green {
  border: #28a677;
  box-sizing: border-box;
  background-color: #28a677;
}

.iris-label-default.label-yellow {
  border: yellow;
  box-sizing: border-box;
  background-color: yellow;
  color: black;
}

.iris-label-default.label-orange {
  border: #fac02e;
  box-sizing: border-box;
  background-color: #fac02e;
}
.iris-label-outline.label-orange {
  color: #fac02e;
  border: 1px solid currentColor;
  box-sizing: border-box;
}

.iris-label-default.label-grey {
  border: #9aa0a6;
  box-sizing: border-box;
  background-color: #9aa0a6;
}

.iris-label-outline.label-grey {
  color: #9aa0a6;
  border: 1px solid currentColor;
  box-sizing: border-box;
}

.iris-label-default.label-misc {
  border: #b5d2fe !important;
  box-sizing: border-box;
  background-color: #b5d2fe !important;
}

.iris-label-outline.label-misc {
  color: #b5d2fe !important;
  border: 1px solid currentColor;
  box-sizing: border-box;
}
