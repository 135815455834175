.primary-checkbox {
  width: 174px;
  height: 40px;
  background: $cp-white;
  box-sizing: border-box;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: map-get($primary, 500);
  border: 1px solid map-get($primary, 500);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.4rem;
  &_checkbox {
    visibility: hidden;
    width: 0px;
  }
  &_checkmark {
    width: 1rem;
    height: 1rem;
    background: $cp-white;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid map-get($primary, 500);
    position: relative;
    z-index: 0;
    &::after {
      content: "";
      width: 5px;
      height: 9px;
      border: solid #ffffff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg) translate(-0.1rem, -0.1rem);
    }
  }
  &_checkbox:checked ~ &_checkmark {
    background-color: map-get($primary, 500);
  }
  &_label {
    margin-left: 0.4rem;
    z-index: 1;
  }

  &.red {
    @include checkbox-style(map-get($error, 400));
  }
  &.grey {
    @include checkbox-style(#596782);
  }
}

// Checkbox secondary
.secondary-checkbox {
  background-color: $cp-white;
  height: 3rem;
  border-radius: 2px;
  color: map-get($primary, 500);
  border: 0.07143rem solid map-get($primary, 500);

  display: inline-flex;
  align-items: center;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
    margin: 0;
    line-height: normal;
  }

  &__input {
    position: relative;
    width: 1.785rem;
    height: 1.785rem;
    margin-right: 1rem;
    input[type="checkbox"] {
      visibility: hidden;
    }

    &__checkmark {
      &::before {
        content: "";
        height: 25px;
        width: 25px;
        background: url("/assets/icons/checkbox_outline.svg");
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
    input:checked ~ &__checkmark::before {
      background: url("/assets/icons/checkbox_selected.svg");
    }
  }

  &:hover {
    color: $cp-white;
    background-color: map-get($primary, 300);
  }

  &:active {
    color: $cp-white;
    background-color: map-get($primary, 800) !important;
  }

  &.active {
    color: $cp-white;
    background-color: map-get($primary, 500);
  }

  &.grey-active {
    background-color: #dee1e6 !important;
    color: $cp-white !important;
  }

  &.grey {
    @include checkbox-style(#dee1e6);
  }
}

.invisible-checkbox {
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"]:checked + .checkbox-alias {
    height: 100% !important;
    background: map-get($map: $primary-new, $key: 100);
  }
}

.cp-form-check {
  &_container {
    display: flex;
    border: 1px solid map-get($map: $cool-grey, $key: 200);
    padding: 0.28rem 0.57rem;
    font-weight: 500;
    color: map-get($map: $text, $key: 400);
    border-radius: 2px;
    background-color: $cp-white;
  }
  &_input:checked ~ &_container {
    border: 1px solid map-get($map: $primary, $key: 200);
    background-color: map-get($map: $primary, $key: 200);
    color: map-get($map: $primary, $key: 900);
  }
  &_checkmark {
    margin-right: 0.6rem;
    display: none;
  }
  &_input:checked ~ &_container &_checkmark {
    display: flex;
    &::before {
      content: "";
      width: 0.5rem;
      height: 1rem;
      border-bottom: 0.2rem solid map-get($map: $primary, $key: 900);
      border-right: 0.2rem solid map-get($map: $primary, $key: 900);
      transform: rotate(45deg);
    }
  }
}

.lab-nav-checkbox-pseudo {
  &.mat-selection-list {
    display: flex;
    height: 100%;
    gap: 8px;
    flex-direction: column;
    white-space: nowrap;
    .mat-list-option {
      font-size: $font-size-base;
      height: 32px;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      .mat-list-text {
        padding: 0 !important;
      }

      .mat-list-item-content {
        width: 100%;
      }
    }
  }
  &.mat-list-base {
    padding: 0 !important;
  }
  .mat-pseudo-checkbox {
    display: none !important;
  }

  mat-list-option[aria-selected="false"]:hover,
  mat-list-option[aria-selected="false"]:focus,
  mat-list-option[aria-selected="false"]:active {
    span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
    }
    .svg::after {
      content: url("../../../assets/icons/add_frameonly.svg");
      margin-top: 0.3rem;
    }
    .svg:hover::after {
      content: url("../../../assets/icons/add_boxed.svg");
      margin-top: 0.3rem;
    }
  }
  mat-list-option[aria-selected="true"],
  mat-list-option[aria-selected="true"]:hover,
  mat-list-option[aria-selected="true"]:focus,
  mat-list-option[aria-selected="true"]:active {
    background: map-get($map: $primary-new, $key: 100);
    color: map-get($map: $primary-new, $key: 400);
    span {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      white-space: nowrap;
      color: map-get($map: $primary-new, $key: 400);
    }
    .svg::after {
      content: url("../../../assets/icons/remove.svg");
      padding: 4px;
      margin-top: -0.5rem;
    }
    .svg:hover::after {
      content: url("../../../assets/icons/remove.svg");
      background-color: map-get($map: $primary-new, $key: 200);
      border-radius: 0.143rem;
      margin-top: -0.5rem;
    }
  }
}
