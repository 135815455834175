@for $i from 1 through 12 {
  .cp-p-#{$i} {
    padding: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-px-#{$i} {
    padding-left: #{$i * 8}px;
    padding-right: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-py-#{$i} {
    padding-top: #{$i * 8}px;
    padding-bottom: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-pb-#{$i} {
    padding-bottom: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-pt-#{$i} {
    padding-top: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-pl-#{$i} {
    padding-left: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-pr-#{$i} {
    padding-right: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-m-#{$i} {
    margin: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-mx-#{$i} {
    margin-left: #{$i * 8}px;
    margin-right: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-my-#{$i} {
    margin-top: #{$i * 8}px;
    margin-bottom: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-mr-#{$i} {
    margin-right: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-ml-#{$i} {
    margin-left: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-mt-#{$i} {
    margin-top: #{$i * 8}px;
  }
}

@for $i from 1 through 12 {
  .cp-mb-#{$i} {
    margin-bottom: #{$i * 8}px;
  }
}

@for $i from 1 through 4 {
  .cp-h-#{$i} {
    height: #{$i * 8};
  }
}

.cp-mt-form-icon {
  margin-top: 2rem;
}

.cp-ml-auto {
  margin-left: auto;
}

.cp-overflow-auto {
  overflow: auto;
}

.cp-mr-auto {
  margin-right: auto;
}

.cp-mt-auto {
  margin-top: auto;
}

.cp-list-styling {
  margin: 0;
  padding-left: 1rem;
  list-style: none;
  & li::before {
    content: "\2022";
    color: map-get($map: $yellow, $key: 500);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
}
