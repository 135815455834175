.date-time-picker-comp,
.order-date-time-picker-comp {
  .mat-form-field-infix {
    border-top: none !important;
    padding: 0 !important;
  }

  .mat-form-field input {
    margin-top: 0 !important;
  }

  .now-button > button {
    max-height: 40px !important;
    padding: 12px 16px !important;
  }

  .date-range-picker-cont {
    > .mat-form-field
      > .mat-form-field-wrapper
      > .mat-form-field-flex
      > .mat-form-field-infix {
      width: 250px !important;
    }

    div[class~="mat-date-range-input-start-wrapper"] {
      max-width: calc(54% - 4px) !important;
      width: calc(54% - 4px) !important;
      height: 40px;
    }

    div[class~="mat-date-range-input-end-wrapper"] {
      max-width: calc(46% - 4px) !important;
      width: calc(46% - 4px) !important;
      height: 40px;
    }

    div[class~="mat-date-range-input-start-wrapper"] {
      > input {
        padding: 12px 8px 12px 32px !important;
      }
    }
  }

  .date-picker {
    input {
      padding: 12px 8px 12px 36px;
    }

    .mat-form-field-infix {
      width: 9.063rem !important;
    }
  }

  .time-picker {
    input {
      padding: 12px 16px;
    }

    .mat-form-field-infix {
      width: 4.25rem !important;
    }
  }

  .date-picker-cont,
  .time-picker-cont,
  .date-range-picker-cont {
    div[class~="mat-form-field-wrapper"] {
      div[class~="mat-form-field-flex"] {
        position: relative;
      }

      div[class~="mat-form-field-prefix"] {
        div[class~="cdk-focused"],
        div[class~="cdk-program-focused"] {
          background-color: transparent !important;
        }

        position: absolute;
        bottom: 11px;
        left: 8px;
        z-index: 1;
      }

      div[class~="mat-form-field-suffix"] {
        position: absolute;
        font-size: 10px;
        bottom: 20px;
        right: 10px;
      }
    }
  }
}

.date-time-picker-comp {
  .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.date-selector {
  & div[class~="mat-form-field-flex"] {
    position: relative;
  }

  & div[class~="mat-form-field-suffix"] {
    top: 50%;
    position: absolute;
    left: 0.28571rem;
    transform: translateY(-50%);
    font-size: 1.14286rem;
  }

  & input[class~="date-picker-input"] {
    padding-left: 2.17143rem;
    margin-top: 0px;
  }
}

.cp-date-input {
  & .date-input {
    background: $cp-white;
    padding: 0.8rem 0rem;
    padding-left: 2.8rem;
  }
  & .mat-form-field-prefix {
    position: absolute;
    top: 50%;
    z-index: 1;
    transform: translateY(-50%);
    left: 9px;
  }
  & label.mat-form-field-label {
    font-size: 14px;
    font-weight: 500;
    color: #484848;
  }
  & .mat-form-field-suffix {
    position: absolute;
    top: calc(50% + 0.1rem);
    z-index: 1;
    transform: translateY(-50%);
    right: 6px;
    font-size: 9px;
  }
  & .cp-date-icon {
    min-height: 1.6rem;
  }
}

.cp-table-text {
  overflow: hidden;
  text-overflow: ellipsis;
}
