// @font-face {
// 	font-family: Roboto;
// 	src: url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");
// }

/*======================================
  				Font-family
=======================================*/

$font-family-base: Roboto, Arial !default;

/*======================================
  							Body
=======================================*/

$line-height-base: 0.8rem !default;
$font-size-base: 14px;

/*======================================
  							Headers
=======================================*/
$font-size-h1: 2.87rem !default; // ~40px
$font-size-h2: 2.28rem !default; // ~32px
$font-size-h3: 1.71rem !default; // ~24px
$font-size-h4: 1.143rem !default; // ~16px

$line-height-h1: 2.87rem !default; // ~40px
$line-height-h2: 2.28rem !default; // ~32px
$line-height-h3: 1.71rem !default; // ~24px
$line-height-h4: 1.14rem !default; // ~16px

/*======================================
  							Caption
=======================================*/

$font-size-c1: 0.74rem !default; // ~10px
$font-size-c2: 0.85rem !default; // ~10px
$font-size-c3: 1.1rem !default; // ~16px

$line-height-c1: 1.14rem !default; // ~16px
$line-height-c2: 0.74rem !default; // ~10px
$line-height-c3: 0.74rem !default; // ~10px

strong,
b {
  font-weight: 600 !important;
}
