/*=====================================
  Variables Declaration
=======================================*/

/*===={ unknown variables }==== */

$primary: lighten(#364e68, 10%);
$secondary: #f79646;
$light: #e6f9ff;
$table-dark-bg: lighten(#364e68, 10%);
$table-dark-border-color: white;
$theme-colors: (
  "primary": lighten(#364e68, 10%),
  "secondary": #f79646,
);

/*===={ Dimensions used }==== */
$popupContentHeight: calc(100vh - 316px);

/*===={ Used variables }==== */

$cp-white: white;
$cp-black: black;
$cp-red: red;
$cp-yellow: #ffd700;
$cp-chocolate: #d2691e;
$cp-disabled: darkgray;
// $cp-primary: #55bf9c;
// $cp-secondary: #f79646;
$cp-info: #00afe6;
$cp-light: #e6f9ff;
$cp-dark: #2f4f4f;
$cp-male: blue;
$cp-female: #ff5c77;
$cp-discharged: #f8d8d6;
$cp-grey: lighten(grey, 30%);
$cp-grey-dark: rgb(149, 145, 145);
$cp-sbar: #693f7b;
$cp-signout: #4987cb;
$cp-shift: #a11692;
$cp-med: #00a6fb;
$cp-lab: #a171bb;
$cp-diet: #e88880;
$cp-comm: #8d9a5d;
$cp-procedure: #173f5f;
$cp-blood: #d40404;
$cp-protocol: #5c6bc0;
$cp-admission: #00ced1;
$cp-event: #b4869f;
$cp-nurse: #900087;
$cp-pharma: #00bdfb;
$cp-progress: #a52a2a;
$cp-intake: #c7e372;
$cp-output: #f7a1aa;
$cp-nav: #a2dcc9;
$header-height: 3.5em;
$footer-height: 2em;
$patient-header: 7.5em;
$cp-fs-btn: 0.875rem;
$cp-brand-green: #55bf9c;
$cp-brand-p: #132238;
$cp-primary: #47678a;
$cp-secondary: #f79646;
$cp-info-p: #00afe6;
$cp-info-p-dark: #006280;
$cp-info-s: #3eafe673;
$cp-misc-p: #98ccd3;
$cp-misc-s: #dce7f5;
$cp-misc-t: #ebf0f6;
$cp-danger: red;
$cp-discharged: #f8d8d6;
$cp-fair: #00e600;
$cp-watcher: gold; //#fdef2c;
$cp-comfort: #8080ff;
$cp-unstable: #ff6666;
$cp-pend: #ffc107;
$cp-i-link: #0000ee;
$cp-e-link: #0000ee;
$cp-input-outline: #4d90d6;
// Material Colors
$cp-m-green-light: #4caf50;
$cp-m-green: #388e3c;
$cp-m-red: #f44336;
$cp-m-blue: #1976d2;
$cp-m-yellow: #ffb300;
$cp-m-grey: #e0e0e0;
$cp-m-grey-dark: #757575;
$cp-m-grey-1: #bdbdbd;
$cp-m-grey-2: #8b94b3;
$cp-mp-red: #d50000;
$cp-mp-red-light: #e46565;

$cp-vent: #880e4f;

$cp-code-blue: #0092cc;
