/** BGB classes */

ngb-tabset {
  // orders Tab
  #active.nav-link.active {
    background: $cp-primary;
    color: $cp-light;
    font-weight: bold;
    border-color: $cp-primary;
    .signed-title {
      background: $cp-light;
      color: $cp-primary;
    }
  }
  #pending.nav-link.active {
    background: $cp-yellow;
    color: $cp-dark;
    font-weight: bold;
    border-color: $cp-yellow;
    .pended-title {
      background: $cp-dark;
      color: $cp-yellow;
    }
  }
  #pta.nav-link.active {
    background: $cp-info;
    color: $cp-light;
    font-weight: bold;
    border-color: $cp-info;
    .pta-title {
      background: $cp-light;
      color: $cp-info;
    }
  }
  #completed.nav-link.active {
    background: $cp-grey;
    color: $cp-dark;
    font-weight: bold;
    border-color: $cp-grey;
    .completed-title {
      background: $cp-white;
      color: $cp-dark;
    }
  }
  #inactive.nav-link.active {
    background: $cp-grey;
    color: $cp-dark;
    font-weight: bold;
    border-color: $cp-grey;
    .inactive-title {
      background: $cp-white;
      color: $cp-dark;
    }
  }
  // PatientList Tab
  #patients.nav-link.active {
    .patients-title {
      background: white;
      color: $cp-primary;
    }
  }
  #sbar.nav-link.active {
    .sbar-title {
      background: white;
      color: $cp-primary;
    }
  }
  .nav-pills {
    a.nav-link {
      background-color: $cp-misc-s;
      color: $cp-primary;
      margin-right: 1px;
    }
    a.nav-link.active {
      background-color: $cp-primary;
      color: white;
    }
  }
  .nav-item #gcs {
    background-color: $cp-fair;
    span {
      color: white;
    }
  }
}

// ngb-tabset.orders-tabs{
//   .tab-content{
//     height:
//   }
// }
ngb-carousel {
  &:focus {
    outline: none !important;
  }
  .carousel-control-prev,
  .carousel-control-next {
    background-color: #ccc;
    width: 10%;
  }
  .carousel-indicators {
    bottom: -30px;
    li {
      background-color: $cp-primary;
    }
    li.active {
      background-color: darken($color: $cp-primary, $amount: 10%);
    }
  }
}

ngb-tabset {
  .nav-tabs {
    width: 100%;
    border-bottom: 1px solid $cp-primary;
    .nav-link {
      &:hover {
        border-color: lighten($color: $cp-disabled, $amount: 20)
          lighten($color: $cp-disabled, $amount: 20) $cp-primary;
      }
    }
    .nav-link.active {
      position: relative;
      color: $cp-white;
      background-color: $cp-primary;
      border-color: $cp-primary;
      font-weight: bold;
    }
  }
}

ngb-timepicker {
  .ngb-tp-input {
    @extend %cp-input-properties;
    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }
}

ngb-accordion {
  .card-header {
    background-color: $cp-primary;
    .btn-link {
      color: white;
    }
  }
}

.preset-accordion ngb-accordion {
  .card-header {
    background-color: white !important;
  }
}

.protocol-accordion ngb-accordion {
  .card-header {
    background-color: white !important;
    padding: 0 !important;
  }
  .card-body {
    padding: 0 !important;
  }

  .card:first-of-type {
    border-radius: 0;
  }
}

/** Modal classes */

.modal-content {
  .modal-header {
    background-color: $cp-info-s;
    color: $cp-primary;
    .cp-btn.close {
      margin-right: 0.1rem;
      margin-top: 0.1rem;
    }
  }
  .modal-body {
    max-height: 85vh;
    overflow: auto;
    padding: 2rem;
  }
}

/** Loading screen classes */

/** Succcess message classes */

.success_message_display {
  position: fixed;
  left: 50px;
  bottom: 50px;
  padding: 15px 40px;
  text-align: center;
  z-index: +10;
  background-color: $cp-info;
  color: $cp-white;
  .s_message {
    font-size: 1rem;
  }
}

/** No Data message classes */

.no_data_found_block {
  font-size: 5rem;
  color: #ccc;
  opacity: 0.5;
  text-align: center;
  .additional_info {
    font-size: 2rem;
  }
}

/** Custom hosp unit classes */

.hospUnits {
  padding: 3px;
  display: none;
  width: 1.2em;
  background-color: $cp-info-p;
  color: $cp-white;
}

.hospUnits.show_units {
  display: inline-block;
}

/** Table classes */

.cp-table {
  table-layout: fixed;
  width: 100%;
  cursor: default;
  th,
  td {
    vertical-align: middle;
  }
  thead {
    tr {
      th {
        background-color: $cp-primary;
        color: white;
      }
    }
  }
  tbody {
    tr:nth-child(even) {
      background-color: $cp-misc-t;
    }
  }
}

.cp-table.cp-table-sticky {
  thead {
    tr {
      th {
        position: sticky;
        z-index: +1;
        top: 0;
        cursor: default;
      }
    }
  }
}

.cp-table.cp-table-hover {
  tbody {
    tr {
      &:hover {
        background-color: $cp-info-s !important;
      }
    }
  }
}

.label-btn {
  border-color: black;
  font-size: 10px;
  min-width: 104px;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  pointer-events: none;
}

.sign-label {
  border-color: black;
  font-size: 10px;
  min-width: 64px;
  margin-left: 0.1rem;
  margin-right: 0.1rem;
  cursor: pointer;
}

.orders-tooltip {
  font-size: 0.8rem;
  min-width: 150px;
}

// checkbox
.chk-container {
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  padding-left: 20px;
  bottom: 12px;
  cursor: pointer;
  user-select: none;
  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    /* Show the checkmark when checked */
    &:checked ~ .checkmark {
      &::after {
        display: block;
      }
    }
  }
  /* Style the checkmark/indicator */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    &::after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid $cp-white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}

// vitals form
.vital_form {
  padding-bottom: 2rem;
  .form_head {
    position: relative;
    h4 {
      b {
        position: absolute;
        top: 0.3rem;
        right: 0.3rem;
        font-size: 2.5rem;
      }
    }
  }
  .form-row {
    .card {
      width: 100%;
    }
  }
  .form_fields {
    .card-header {
      background-color: $cp-misc-t;
    }
  }
}

// Pill holder
.cp-pillholder {
  padding: 0;
  margin: 0;
  .cp-pill {
    border-radius: 0.25rem;
    display: inline-block;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: 0.5s;
    background-color: $cp-misc-s;
    margin-right: 1px;
    &:hover {
      color: $cp-info;
    }
  }
  .cp-pill.active {
    background-color: $cp-primary;
    color: $cp-white;
  }
}

.chk-container:focus-within .checkmark {
  outline: 1px auto $cp-input-outline;
}

.radio-group label:focus-within {
  outline: 1px auto $cp-input-outline;
}

.cp-font-lab {
  color: $cp-lab;
}

.cp-font-med {
  color: $cp-med;
}

.cp-font-protocol {
  color: $cp-protocol;
}

// ::-webkit-scrollbar {
//   width: 5px;
//   height: 10px;
// }

// ::-webkit-scrollbar-track {
//   background: #bbdefb;
// }

// ::-webkit-scrollbar-thumb {
//   background: #2196f3;
// }

// ::-webkit-scrollbar-thumb:hover {
//   background: #1976d2;
// }

.order-row {
  transition: all 0.2s;
  &__selected {
    transform: scale(1.01);
    box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.28);
  }
  &__hide {
    position: absolute;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2) !important;
    width: 100%;
    height: 100%;
  }
}

.radar_policy {
  height: 90vh;
  width: 70%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  text-align: center;
}

.img-container-document-input {
  width: 70%;
}

.img-container-document-input-zoom {
  width: 70%;
  .modal-dialog {
    margin: 0 !important;
    max-width: 70vw;
  }
}

.light-black-backdrop-document-input {
  background-color: #060606;
  width: 70vw;
}

.grecaptcha-badge {
  visibility: hidden;
}

.card-columns {
  column-count: 5;
}

.vital-tabset {
  ul {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
  }

  .tab-content {
    padding-top: 40px;
  }
}

.covid-suspected {
  background: $cp-watcher !important;
  color: $cp-white !important;
}

.med-comb .dropdown-menu {
  font-size: 13px;
  margin: 0;
  overflow: auto;
  max-height: 17rem;
  max-width: 17rem;

  .dropdown-item {
    padding: 0.15rem 0.8rem;
  }

  .dropdown-item.active {
    background-color: #2196f3;
  }
}
