.cp-checkbox {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 15px;
    height: 15px;
    background: #ddd;
  }

  &:hover + label:before {
    background: $cp-info-p;
  }

  &:checked + label:before {
    background: $cp-info-p;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 10px;
    background: #ddd;
    width: 2px;
    height: 2px;
    box-shadow:
            2px 0 0 white,
            4px 0 0 white,
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white;
    transform: rotate(45deg);
  }
}
