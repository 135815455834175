// Mat toggle buttons/merge buttons

mat-button-toggle-group {
  border-radius: 0px;
  // margin-top: 8px;
  width: fit-content;
}

mat-button-toggle {
  // display: flex;
  // align-items: center;
  // width: 160px;
  // height: 42px;
  width: 100%;
  background: none;
}

.mat-button-toggle-checked {
  background-color: map-get($primary, 500) !important;

  button {
    color: $cp-white;
  }
}
