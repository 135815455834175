.modal-body {
    padding: 1.5rem !important;
}

.modal-header {
    display: flex;
    align-items: center;
    padding: .5rem 1.5rem !important;
    
    .close {
        cursor: pointer;
    }
}

.user-edit-modal {
    .modal-dialog {
        max-width: 700px;
    }
}
