.button-toggle-primary {
  button {
    color: map-get($primary, 500);
    &:hover {
      color: $cp-white;
      background-color: map-get($primary, 300);
    }

    &:active {
      color: $cp-white;
      background-color: map-get($primary, 500) !important;
    }
  }
}

.button-toggle-secondary {
  gap: 4px;
  .mat-button-toggle {
    font-weight: 500;
    border: 1px solid map-get($map: $primary, $key: 500) !important;
    color: map-get($map: $primary, $key: 500);
    border-radius: 2px;

    &:hover {
      color: $cp-white;
      background-color: map-get($map: $primary, $key: 300);
    }
  }
}
