// overriding material classes for specific components
@import "abstracts/variable";

.emr-nav-tabs-comp {
  .nav-tabs-btns {
    .button-group-vertical > div {
      padding: 8px 16px !important;
      justify-content: space-between !important;
    }

    button,
    > div > div {
      margin: 4px 0 !important;
      width: 100% !important;
      font-weight: 550 !important;

      .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .icon-right {
        width: 24px !important;
      }
    }
  }
}

.patient-header-comp {
  .camera,
  .sbar-create {
    button {
      height: 32px !important;
      padding: 6px 16px !important;

      .icon-right {
        width: 20px !important;
        margin-bottom: 2px;
      }
    }
  }

  .mat-accordion {
    width: 100%;

    > .mat-expansion-panel {
      color: map-get($map: $text, $key: 400);
      box-shadow: none !important;

      > .mat-expansion-panel-header {
        height: $patient-header-height !important;
        padding: 12px 8px 0 16px !important;
        font-size: 12px !important;
        align-items: start !important;

        &:hover {
          background-color: map-get($map: $bg, $key: 000) !important;
        }
        > .mat-content {
          justify-content: space-between;
        }

        .arrow-up,
        .arrow-down {
          position: absolute;
          right: 0.8rem;
          bottom: 0.6rem;
          width: 24px;
        }

        .arrow-up {
          visibility: hidden;
        }
        .arrow-down {
          visibility: visible;
        }
      }

      > .mat-expansion-panel-header.mat-expanded {
        height: 2 * $patient-header-height !important;

        .arrow-up {
          transform: rotate(180deg);
          visibility: visible;
          bottom: 7.8rem;
        }
        .arrow-down {
          visibility: hidden;
        }
      }
    }

    .cp-clr-discharged {
      > .mat-expansion-panel-header {
        &:hover {
          background-color: map-get($map: $error, $key: 100) !important;
        }
      }

      .mat-expansion-panel-content {
        background-color: map-get($map: $error, $key: 100) !important;

        > .mat-expansion-panel-body > .end-part > .view-past-admissions {
          padding-right: 10rem !important;
        }
      }
    }

    .cp-clr-re-admitted {
      > .mat-expansion-panel-header {
        &:hover {
          background-color: map-get($map: $orange, $key: 100) !important;
        }
      }

      .mat-expansion-panel-content {
        background-color: map-get($map: $orange, $key: 100) !important;
      }
    }
  }

  > .mat-accordion > .mat-expansion-panel > {
    .mat-expansion-panel-content {
      position: absolute;
      top: 104px;
      right: 0px;
      width: 100%;
      z-index: 100;
      background: map-get($map: $bg, $key: 000);
      font-size: 12px !important;
      visibility: visible !important;
      height: 104px !important;
      border-top: 1px solid map-get($map: $bg, $key: 300);
      border-bottom: 1px solid map-get($map: $bg, $key: 300);

      > .mat-expansion-panel-body {
        padding: 12px 8px 0 16px !important;
        display: flex;
        justify-content: space-between;

        .end-part > .view-past-admissions {
          padding-right: 17rem;
        }
      }
    }
  }
}

.input-popup-comp {
  .mat-tab-group .mat-tab-header .mat-tab-labels {
    .mat-tab-label {
      min-width: 123px !important;
      max-width: 123px !important;
    }
  }

  .mat-tab-group .mat-tab-header {
    position: sticky !important;
    top: 0;
    z-index: 0;
    background: #f6f7f9;
  }
}

.input-popup-comp
  .mat-tab-body
  > .mat-tab-body-content
  > app-chat
  > .chat-module {
  .chat-view-window {
    padding: 24px 16px;
    margin-bottom: 4rem;
    top: 0;

    .chat-message {
      .chat-msg-holder {
        padding: 12px 16px !important;

        > .replyTo_wrapper {
          left: -11px !important;
          top: -8px !important;
          width: 106% !important;
        }
      }

      .chat-text .chat-info > div {
        padding: 0 2px !important;
        margin: 0 2px !important;
      }

      .chat-text .chat-msg {
        width: 80% !important;
      }
    }
  }

  .chat-enter-window {
    padding: 8px;

    > .chat-area > textarea {
      margin-bottom: 0 !important;
      resize: none;
    }

    > .chat-buttons {
      margin: 0;
      > div {
        margin: 8px;
      }

      > .send-btn > img {
        width: 24px;
      }
    }
  }

  .read_reciept_wrapper .reciept_container {
    width: 500px;
    height: 500px;

    .read_by_list .readby-name {
      max-height: 220px !important;
    }
  }

  .delete_message_wrapper .delete_message_container {
    width: 400px;
  }
}

.summary-comp {
  > .summary-options-select .mat-form-field {
    width: 350px !important;
  }

  .select-input {
    background-color: map-get($map: $bg, $key: 000) !important;
  }
}

#ordersViewContent {
  .filters-cont .mat-form-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.input-popup-comp .popup-box .content-holder .mat-tab-group {
  height: 100%;

  > .mat-tab-body-wrapper {
    height: 100%;

    > .mat-tab-body {
      height: 100%;
    }
  }
}
