.cp-text {
  &-bold {
    font-weight: 600;
  }
  &-normal {
    font-weight: 500;
  }
  &-light {
    font-weight: 400;
  }
}
.cp-title-primary {
  color: $cp-black;
  font-size: 1.5rem;
}

.cp-header {
  font-weight: 600;
  font-size: $font-size-h4;
  padding: 8px 0px;
}

.message-text {
  font-size: $font-size-c2;
  color: map-get($map: $text, $key: 300);
}

.cp-sub-header {
  color: map-get($map: $text, $key: 400);
  white-space: normal;
  padding-left: 0.95rem;
  font-size: 16px;
}

.cp-small-font {
  font-size: 12px;
}

.cp-normal-font {
  font-size: 1rem;
}

.cp-big-font {
  font-size: 16px;
}

.cp-large-text {
  font-size: 32px;
  color: map-get($map: $text, $key: 400);
  font-weight: 400;
}

.cp-big-font-2 {
  font-size: 20px;
}

.cp-color-primary {
  color: map-get($map: $primary, $key: 500);
}

.cp-color-dark {
  color: map-get($map: $text, $key: 500);
}

.cp-color-dark-2 {
  color: map-get($map: $text, $key: 600);
}

.cp-color-normal {
  color: map-get($map: $text, $key: 400);
}

.cp-color-light {
  color: map-get($map: $text, $key: 300);
}

.cp-info-dark {
  color: map-get($map: $text, $key: 500);
  font-size: 1rem;
}

.cp-info-light {
  color: map-get($map: $text, $key: 300);
  font-size: 1rem;
}

.cp-label-text {
  color: $cp-black;
  white-space: normal;
  padding: 1px;
  font-size: 14px;
}

.cp-timezone-label-container {
  font-size: 14px;
  color: map-get($text, 500);
  font-weight: 400;
  line-height: 16px;

  &--timezone-value {
    font-weight: 500;
  }
}
