$search-icon-space: 3rem;

.cp-search {
  position: relative;
  &_input {
    border: 1px solid map-get($map: $bg, $key: 400);
    padding: 0.75rem 1rem;
    padding-left: $search-icon-space;
    background-color: $cp-white;
    width: 100%;
    height: 100%;
    &:focus {
      border: 1px solid map-get($map: $primary, $key: 500);
    }
  }
  &_icon {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: $search-icon-space/2;
  }
}
