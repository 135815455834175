//  Mat Table

.mat-cell,
.mat-header-cell {
  font-size: 12px !important;
  padding: 12px 16px !important;
}

.mat-header-cell {
  border-bottom-color: map-get($bg, 400) !important;
  background-color: map-get($bg, 200) !important;
  z-index: +1;
  position: sticky;
  top: 0px;
}

.mat-table {
  border: 1px solid map-get($bg, 400) !important;
}

.mat-table.cp-table-selection {
  & .mat-icon[svgicon="cp-edit"] {
    visibility: hidden;
  }

  & .mat-row:hover {
    background: map-get($map: $primary, $key: 50);
    & .mat-icon[svgicon="cp-edit"] {
      visibility: visible;
    }
  }
}

tr.mat-header-row {
  height: 40px !important;
}

// Mat Tab

.mat-tab-container {
  .mat-tab-label-active {
    opacity: 1;
  }
}

.mat-tab-labels > .mat-tab-label {
  border-bottom: 3px solid map-get($bg, 400) !important;
}

.mat-tab-label-active {
  color: map-get($map: $primary, $key: 500) !important;
}

.mat-tab-label-content {
  font-family: $font-family-base;
  font-weight: 600 !important;
}

.content-holder
  > .mat-tab-group
  > .mat-tab-body-wrapper
  > .mat-tab-body
  > .mat-tab-body-content {
  // min-height: 53rem;
}

// To show ink-bar for the first time when order/chat tab is opened.
.input-popup-comp .mat-tab-group.mat-primary .mat-ink-bar {
  width: 123px !important;
}

// Mat tab header
.mat-tab-group {
  &--no-border .mat-tab-header {
    border-bottom: 0px;
  }
}

// Mat-dialog

.mat-dialog-container {
  padding: 0 !important;
}

// Mat Progress Spinner

.unit-chat-comp > .wrapper > .chat-box > .blur-bg > .spinner,
.content-holder .orders-input-comp > .blur-bg > .spinner {
  > .mat-spinner {
    width: 3.5rem !important;
    height: 3.5rem !important;

    > svg {
      width: 3.5rem !important;
      height: 3.5rem !important;
    }
  }
}

.button-element > .spinner {
  > .mat-spinner {
    width: 20px !important;
    height: 20px !important;

    > svg {
      width: 20px !important;
      height: 20px !important;
    }
  }
}

// Mat Button Toggle

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 40px !important;
}

// Mat Tooltip
.mat-tooltip {
  font-size: 13px !important;
}

// Mat Accordion

mat-expansion-panel,
mat-expansion-panel * {
  transition: none !important;
}

// Mat Sort

.mat-sort-header-container {
  .mat-sort-header-arrow {
    display: none !important;
    color: map-get($map: $text, $key: 600) !important;
  }

  &:hover {
    color: map-get($map: $text, $key: 600) !important;
    font-weight: 700;
  }
}

.mat-sort-header-sorted {
  color: map-get($map: $text, $key: 600) !important;
  font-weight: 700;

  .mat-sort-header-arrow {
    display: inline !important;
  }
}

// Mat Button Toggle

.meta-freq-comp .urgency-cont .mat-button-toggle-group {
  width: 8rem;

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 26px !important;
    padding: 0 !important;
    font-weight: 500;
    letter-spacing: 1.2px;
    color: map-get($map: $text, $key: 400);
  }

  .mat-button-toggle-checked .mat-button-toggle-label-content {
    color: map-get($map: $text, $key: 100) !important;
  }
}

// Mat menu height

.cp-menu-container {
  height: auto;
  max-height: 12rem;
}
app-order-panel mat-expansion-panel.trigger-panel {
  .mat-expansion-panel-body {
    padding: 0 !important;
    overflow: auto !important;
  }
}

app-ncp-view mat-expansion-panel.trigger-panel {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

.cp-mat-expansion-panel-no-padding {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

app-open-webcam-modal mat-dialog-actions {
  padding: 0 !important;
  margin-bottom: 0px !important;
  min-height: 0px !important;
}
// Side scroller

.cp-mat-virtual-scroll {
  .cdk-virtual-scroll-content-wrapper {
    width: 100%;
  }
}

.mat-icon {
  &.cp-mat-icon-overflow-initial {
    overflow: initial;
  }
}

.cp-paginator-hide-arrow.mat-paginator {
  & .mat-paginator-range-label {
    margin-right: 0;
  }
  & .mat-paginator-navigation-previous,
  & .mat-paginator-navigation-next {
    display: none;
  }
}

.mat-form-field.mat-focused.mat-primary .cp-custom-mat-input .mat-select {
  &:not(&:focus) .mat-select-arrow {
    color: rgba(0, 0, 0, 0.54);
  }
}
