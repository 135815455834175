@import "abstracts/variable";

.patients-list-comp {
  .mat-tab-group .mat-tab-header .mat-tab-labels {
    .mat-tab-label {
      min-width: 116px !important;
      max-width: 116px !important;
    }
  }

  .mat-tab-group
    > .mat-tab-body-wrapper
    > .mat-tab-body
    > .mat-tab-body-content {
    overflow-x: hidden;
  }

  .pat-list-comp {
    display: flex;
    flex-direction: column;
    position: relative;
    height: calc(
      100vh - #{$mat-tab-group-height + $iris-header-height +
        $header-divider-height + $iris-footer-height +
        $patient-list-label-height + 16px}
    );

    mat-table tr {
      th:last-child,
      td:last-child {
        max-width: 156px !important;
        min-width: 156px !important;
      }

      .mat-cell,
      .mat-header-cell {
        min-width: 118px !important;
        max-width: 119px !important;
        padding: 8px 16px !important;
      }
    }
  }

  .pat-list-comp,
  .shift-assign-comp,
  .signout-comp {
    .mat-form-field-wrapper {
      padding-bottom: 0px !important;
    }
  }

  .shift-assign-comp {
    .mat-tab-group .mat-tab-header .mat-tab-labels {
      background-color: map-get($map: $bg, $key: 200);
    }

    .mat-content {
      overflow: inherit;
      .mat-expansion-panel-header-title,
      .mat-expansion-panel-header-description {
        position: sticky;
      }
      .mat-expansion-panel-header-title {
        left: 12px;
      }
      .mat-expansion-panel-header-description {
        right: 12px;
      }
    }

    .mat-expansion-panel {
      overflow: initial;
    }

    .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-content {
      overflow: hidden;
    }

    .table-cont .mat-table {
      tr {
        th:first-child,
        td:first-child {
          min-width: 157px !important;
          max-width: 157px !important;
          left: 0;
          z-index: 10;
          background: map-get($map: $bg, $key: 000);
        }

        th:last-child,
        td:last-child {
          right: 0;
          z-index: 10;
          background: map-get($map: $bg, $key: 000);
          width: 122px;
        }

        th.link-column-2 {
          width: 7rem;
        }

        .mat-cell,
        .mat-header-cell {
          padding: 8px 16px !important;
        }
      }
    }
  }

  .signout-comp {
    .table-cont .mat-table {
      width: 100%;

      tr {
        .mat-cell,
        .mat-header-cell {
          padding: 8px 16px !important;
        }
      }
    }
  }

  .hospitals-btn-grp {
    > .selected-btn {
      color: map-get($map: $text, $key: 100) !important;
      background-color: map-get($map: $primary, $key: 500) !important;
    }

    > .hospital-btn {
      padding: 6px 16px !important;
      margin: 0 !important;
      font-weight: 550 !important;

      .checkbox-icon {
        width: 24px !important;
      }

      .mat-button-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .mat-checkbox {
          .mat-checkbox-layout {
            display: contents;
          }
        }
      }
    }
  }

  .hospitals-list
    .filter
    > .input-cont
    > .mat-form-field
    > .mat-form-field-wrapper {
    padding-bottom: 8px !important;
    > .mat-form-field-flex > .mat-form-field-infix {
      width: $hospital-list-width - 32px !important;
      border-top: none !important;
    }
  }

  .mat-paginator {
    background-color: transparent !important;
  }

  .hospitals-list .select-all {
    .mat-checkbox-inner-container {
      height: 18px;
      width: 18px;
    }
    .mat-checkbox-frame {
      border-color: map-get($map: $primary, $key: 500);
      border-width: 1px !important;
    }
  }
}
