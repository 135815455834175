/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

// Checkbox color mixins

@mixin checkbox-style($color) {
  color: $color;
  border: 1px solid $color;
  & .primary-checkbox_checkmark {
    border: 1px solid $color;
    border-radius: 2px;
  }
  & .primary-checkbox_checkbox:checked ~ .primary-checkbox_checkmark {
    background-color: $color;
  }
}

@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

@import "./table";
