@import "../../abstracts/color";

.button-group-horizontal,
.button-group-vertical {
  display: flex;

  & > .button-primary {
    &:hover {
      background-color: map-get($primary, 700) !important;
    }

    &:focus,
    &:active {
      background-color: map-get($primary, 800) !important;
    }
  }

  & > .button-secondary {
    &:hover {
      background-color: map-get($primary, 300) !important;
    }

    &:focus,
    &:active {
      background-color: map-get($primary, 500) !important;
    }
  }
}

.button-group-vertical {
  flex-direction: column;

  & > button,
  & > div {
    &:first-child {
      border-radius: 2px 2px 0 0 !important;
    }

    &:last-child {
      border-radius: 0 0 2px 2px !important;
    }

    width: 192px;
    border-radius: 0 !important;
    justify-content: center;
    margin: 1px 0;
  }
}

.button-group-horizontal > button {
  margin: 0 1px;
  border-radius: 0 !important;
  max-height: 36px;

  &:first-child {
    border-radius: 2px 0 0 2px !important;
  }

  &:last-child {
    border-radius: 0 2px 2px 0 !important;
  }
}

.mat-cell,
.mat-header-cell {
  .button-primary,
  .button-secondary,
  .button-tertiary,
  .button-error,
  .cp-button,
  .button-transparent-dark {
    padding: 4px 16px !important;
  }
}

.button-primary,
.button-secondary,
.button-error,
.button-tertiary,
.button-transparent-dark {
  font-size: 14px !important;
  line-height: 16px !important;
  padding: 10px 16px !important;
  border-radius: 2px !important;
  display: flex;
  align-items: center;
  max-height: 36px;

  & .mat-button-wrapper {
    width: 100% !important;

    & img {
      width: 16px !important;
    }

    & .mat-icon {
      height: 20px !important;
      width: 20px !important;
      font-size: 20px !important;
    }

    & .icon-left {
      margin-right: 8px !important;
    }

    & .icon-right {
      margin-left: 8px !important;
    }
  }
}

.button-primary {
  border: none !important;
  color: $cp-white !important;
  background-color: map-get($primary, 500) !important;

  &:hover {
    background-color: map-get($primary, 300) !important;
  }

  &:active {
    background-color: map-get($primary, 800) !important;
  }

  &:disabled {
    background-color: map-get($cool-grey, 200) !important;
  }
}

.button-secondary {
  border: 1px solid map-get($primary, 500) !important;
  color: map-get($primary, 500) !important;
  background-color: $cp-white !important;

  &:hover,
  &:focus,
  &:active {
    color: $cp-white !important;
  }

  &:hover {
    background-color: map-get($primary, 300) !important;
  }

  &:focus {
    background-color: map-get($primary, 500) !important;
  }

  &:active {
    background-color: map-get($primary, 800) !important;
  }

  &:disabled {
    border: 1px solid map-get($cool-grey, 200) !important;
    color: map-get($cool-grey, 200) !important;
    background-color: map-get($bg, 000) !important;
  }
}

.button-tertiary {
  border: 1px solid map-get($text, 300) !important;
  color: map-get($text, 300) !important;
  background-color: $cp-white !important;

  &:hover,
  &:focus,
  &:active {
    color: $cp-white !important;
  }

  &:hover {
    background-color: map-get($cool-grey, 200) !important;
  }

  &:focus {
    background-color: map-get($text, 300) !important;
  }

  &:active {
    background-color: map-get($text, 400) !important;
  }

  &:disabled {
    border: 1px solid map-get($cool-grey, 200) !important;
    color: map-get($cool-grey, 200) !important;
    background-color: map-get($bg, 000) !important;
  }
}

.button-error {
  border: none !important;
  color: $cp-white !important;
  background-color: map-get($error, 400) !important;

  &:hover {
    background-color: map-get($error, 300) !important;
  }

  &:active,
  &:focus {
    background-color: map-get($error, 600) !important;
  }

  &:disabled {
    background-color: map-get($cool-grey, 200) !important;
  }
}

// Note: only work on mat-flat-button
.mat-flat-button.cp-button {
  font-size: 14px;
  line-height: 16px;
  padding: 10px 16px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  max-height: 36px;
  &--warning {
    color: $cp-white;
    background-color: map-get($map: $orange, $key: 400);
  }
  &--outline-error {
    border: 1px solid map-get($error, 400);
    color: map-get($error, 400);
    background-color: $cp-white;

    &:hover,
    &:focus,
    &:active {
      color: $cp-white;
    }

    &:hover {
      background-color: map-get($error, 300);
    }

    &:focus {
      background-color: map-get($error, 400);
    }

    &:active {
      background-color: map-get($error, 800);
    }

    &:disabled {
      border: 1px solid map-get($cool-grey, 200);
      color: map-get($cool-grey, 200);
      background-color: map-get($bg, 000);
    }
  }
}

.button-transparent-dark {
  border: 1px solid $cp-white !important;
  color: $cp-white !important;
  background-color: transparent !important;

  &:hover {
    border: 1px solid map-get($cool-grey, 300) !important;
    color: $cp-white !important;
    background-color: map-get($cool-grey, 300) !important;
  }

  &:active,
  &:focus {
    border: 1px solid map-get($cool-grey, 400) !important;
    color: $cp-white !important;
    background-color: map-get($cool-grey, 400) !important;
  }

  &:disabled {
    border: 1px solid map-get($cool-grey, 400) !important;
    color: map-get($cool-grey, 400) !important;
    background-color: transparent !important;
  }
}

.fab-icon {
  box-shadow: none !important;
  height: 44px;
  width: 44px;

  &.mat-fab .mat-button-wrapper {
    padding: 0px !important;
  }

  &.small {
    height: 32px;
    width: 32px;

    &.mat-fab {
      line-height: 24px !important;
    }

    &.mat-fab .mat-button-wrapper {
      & > img {
        width: 16px !important;

        :disabled {
          fill: map-get($cool-grey, 100);
        }
      }
    }
  }

  &.square {
    border-radius: 4px !important;
  }

  &.outline {
    border: 1px solid map-get($primary, 500) !important;
    background-color: $cp-white !important;
  }

  &.no-outline {
    border: none !important;
    background-color: $cp-white !important;
  }

  &:hover,
  &.outline:hover,
  &.no-outline:hover {
    background-color: map-get($primary, 200) !important;
  }

  &:active,
  &.outline:active,
  &.no-outline:active {
    background-color: map-get($primary, 800) !important;
  }

  &:focus,
  &.outline:focus,
  &.no-outline:focus {
    background-color: map-get($primary, 500) !important;
  }

  &:disabled {
    background-color: map-get($cool-grey, 200) !important;
  }

  &.outline:disabled {
    border: 1px solid map-get($cool-grey, 200) !important;
  }

  &.outline:disabled,
  &.no-outline:disabled {
    background-color: $cp-white !important;
  }
}

// disabled button icon

.mat-icon-button.mat-button-disabled:disabled {
  opacity: 0.3;
  pointer-events: none;
}

.mat-datepicker-toggle.cp-date-picker-button {
  & .mat-icon-button {
    height: 2rem;
    width: 2rem;
  }
}
