.cursor-not-allowed {
    cursor: not-allowed;
}

.fs-9 {
    font-size: .9rem;
}

.h-5 {
    height: 5rem;
}

/*
* Widths
*/

.w-50 {
    width: 50% !important;
}

.w-80 {
    width: 80% !important;
}

.w-90 {
    width: 90% !important;
}

.w-95 {
    width: 95% !important;
}
