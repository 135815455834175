html,
body {
  margin: 0;
  padding: 0;
  font-size: $font-size-base;
  font-family: $font-family-base;

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    outline: none !important;
  }
}
