// Styling for table layout with pagination
$paginator-height: 4rem;

.cp-table-container {
  display: block;
  height: 100%;
  &__table {
    height: calc(100% - #{$paginator-height});
    overflow: auto;
  }
  & &__pagination {
    background: transparent;
    height: $paginator-height;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  & .table-sort-icon {
    width: 0.4rem;
  }
}

.cp-table-2 {
  border-radius: 4px;
  border: 1px solid map-get($map: $bg, $key: 400);
  border-collapse: separate;
  overflow: auto;
  // background: map-get($map: $blue, $key: 100);
  & thead {
    height: 4.1rem;
    position: sticky;
    top: 0;
  }

  &__head-bg::before {
    content: "";
    width: 100%;
    display: block;
    position: absolute;
    // background: map-get($map: $blue, $key: 100);
    height: 100%;
    z-index: -1;
  }
  &__header-width {
    min-width: 8rem;
  }

  &__main-header {
    font-weight: 500;
    color: map-get($map: $text, $key: 600);
  }

  &__sub-header {
    font-weight: 500;
    color: map-get($map: $text, $key: 500);
  }

  &__parent-cell {
    font-weight: 500;
    color: map-get($map: $text, $key: 600);
  }

  & tr {
    height: 100%;
  }

  & td {
    padding: 0.2rem 0.6rem;
    height: 2.2857rem;
    color: map-get($map: $text, $key: 500);
    background-color: $cp-white;
  }
  & th {
    height: 1rem;
    padding: 0.2rem 0.6rem;
    background-color: map-get($map: $blue, $key: 100);
  }
}

@include table-background("danger", $table-danger);
@include table-background("warning", $table-warning);
