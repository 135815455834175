.ngx-contextmenu {
    width: 260px;

    .dropdown-menu {
        box-shadow: 0 0 5px 5px rgba(0,0,0,0.08);
        border: solid 1px $cp-misc-t;
        background-color: white;
        padding: 0;

        .divider {
            height: 1px;
            margin: 9px 0;
            overflow: hidden;
            background-color: #e5e5e5;
        }
    }

    .dropdown-menu:focus {
        outline: none;
    }

    li {
        display: block;
        text-align: left;
    }

    a {
        color:black;
        display: block;
        text-transform: none;
        text-decoration: none;
    }

    li:first-child {
        padding-top: .5em;
    }

    li:last-child {
        padding-bottom: .5em;
    }

    a:hover {
        color: black;
        background-color: $cp-misc-t;
    }

    &__item {
        padding: 0.2em 1em;
    }

    .hasSubMenu:before {
        font-size: 10px;
        padding-top: 7px;
        padding-right: 1em;
        color: $cp-disabled;
    }
}
