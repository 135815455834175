/* You can add global styles to this file, and also import other style files */

/* You can add global styles to this file, and also import other style files */
@import "assets/styles/variables";
@import "assets/styles/mixins";
@import "assets/styles/custom-bootstrap";
@import "assets/styles/legacy-bootstrap/bootstrap.scss";
@import "assets/styles/cheetstyles";
@import "assets/styles/components/button";
@import "assets/styles/components/icon";
@import "assets/styles/components/utilities";
@import "assets/styles/components/context-menu";
@import "assets/styles/components/modal";
@import "assets/styles/vendor/dragula";
@import "assets/styles/components/checkbox.scss";
@import "assets/styles/components/order.scss";
@import "assets/styles/components/vent.scss";
@import "assets/styles/components/select.scss";
@import "assets/styles/components/accordion.scss";

// @import 'assets/styles/variables';
// @import 'assets/styles/custom-bootstrap';
// @import '~bootstrap/scss/bootstrap';

// @import "./app/iris-components/iris-components.theme.scss";
@import "./app/iris-components/iris.components.scss";
@import "./app/patient/patient.module.scss";
@import "./app/patient-list/patient-list.module.scss";
@import "./app/get-started/get-started.module.scss";
@import "./app/app.module.scss";

@import "src/iris-styles/main.scss";
