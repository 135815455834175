.login-comp > .login-wrapper > .login-body {
  .form-cont > .radial-loader .button-element > .spinner > .mat-spinner {
    width: 3.5rem !important;
    height: 3.5rem !important;

    > svg {
      width: 3.5rem !important;
      height: 3.5rem !important;
    }
  }
}

.phone-number-container .cp-phone-input-comp {
  .cp-mat-dropdown-container {
    padding: 7.25px;
  }
  & .cp-mat-dropdown-container,
  & input {
    font-size: 12px;
  }
}
