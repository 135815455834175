.iris-form {
  .sub-tittle {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.15px;
    margin-left: 6px;
    margin-bottom: 0.57143rem;
  }

  .sub-header {
    padding: 12px;
    margin-bottom: 16px;
    border: 1px solid map-get($map: $bg, $key: 200);
  }

  .iris-v-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .row-left {
      margin-right: 16px;
    }

    .row-right,
    .row-left {
      flex: 1;
      position: relative;
    }

    &.v-row-three {
      & > div {
        width: 30%;
        margin-right: 1%;
        flex: 1;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.iris-form-row {
  margin-bottom: 8px;

  .mat-form-field {
    width: 100%;
  }
}

.form-info {
  font-size: 14px;
  // color: map-get($primary, 500); // not required for hover/focus
}

// Orders Form

.orders-form-row {
  display: grid;
  grid-column-gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
  grid-template-columns: 1fr 1fr;
  .orders-form-row__start {
    grid-column-start: 1;
  }
  .mat-input-element {
    background: $cp-white;
  }
  &--divider {
    border-bottom: 8px solid map-get($map: $bg, $key: 100);
  }
}

// Preset Form

.orderable-preset-form .order-presentation-field {
  display: none;
}

.iris-form-body {
  padding: 1.714rem 0rem;
  &__container {
    padding: 0rem 1rem;
    &--grid {
      display: grid;
      grid-column-gap: 1rem;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      .mat-chip-input {
        width: 100%;
        margin-top: 0.6rem;
        margin-left: 0px;
      }
    }
  }
  .field-header-container {
    background-color: map-get($map: $primary, $key: 100);
    font-weight: 600;
    padding: 0.8rem 0.8rem;
    color: map-get($map: $text, $key: 500);
    margin-bottom: 1rem;
  }
  & &__footer {
    padding-left: 1rem;
    padding-right: 1rem;
    box-shadow: 0px -0.5px 2px rgba(0, 0, 0, 0.25);
  }
}

// mat tap form
.mat-tab-group.cp-mat-tab-form .mat-tab-body-wrapper {
  flex: 1;
}
.cp-mat-tab-form {
  height: 100%;
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &__body {
      overflow: auto;
    }
    &__actions {
      box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.2);
      padding: 8px;
    }
  }
}
// mat tap form
