%flex-property {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.management-section {
  @extend %flex-property;

  .mat-tab-group:not(.not-flex),
  .mat-tab-body-wrapper,
  .mat-tab-body-content {
    @extend %flex-property;
  }

  .section-header {
    padding: 0.857rem 0;
    padding-left: 2.286rem;
    border-bottom: 1px solid map-get($map: $bg, $key: 400);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.643rem;
  }

  .section-body {
    @extend %flex-property;

    .body-content {
      @extend %flex-property;
    }

    .button-section {
      height: 3rem;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .filter-section {
      margin: 0.571rem 0;
      padding: 0.571rem 16px;
      background-color: #fff;
      font-size: $font-size-base;

      &--nav-tab {
        padding: 0px;
        margin-top: 0px;
      }

      .mat-tab-links {
        margin-top: 1rem;
      }

      .f-title {
        color: map-get($map: $text, $key: 500);
        font-weight: 700;
        margin-bottom: 0.857rem;
      }

      .filter-holder {
        display: flex;
        align-items: flex-end;

        .filter-clear {
          border-radius: 2px;
          padding: 2px 4px;
          margin-left: 1.143rem;
        }

        .filter-clear-disabled {
          color: map-get($map: $cool-grey, $key: 200);
        }
      }

      .mat-form-field-appearance-legacy .mat-form-field-wrapper {
        padding-bottom: 0;
      }
    }

    .table-section {
      @extend %flex-property;
      flex-basis: 20px;
      overflow-y: auto;
    }

    table {
      width: 100%;
      table-layout: fixed;
      position: relative;

      thead {
        tr {
          background-color: map-get($map: $bg, $key: 200);

          th {
            position: sticky;
            top: 0;
          }
        }
      }

      .table-edit {
        height: 1.714rem;
        width: 1.714rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }

  .no_data_found_block {
    margin-top: 0.571rem;
    background-color: #fff;
  }
}
