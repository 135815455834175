@import "./break-point";
@import "./color";
@import "./fonts";

$iris-header-height: 60px;
$iris-footer-height: 24px;
$header-divider-height: 8px;
$nav-tabs-width: 327px;
$patient-header-height: 104px;
$vertical-tab-width: 48px;
$hospital-list-width: 295px;
$mat-tab-group-height: 49px;
$patient-list-label-height: 66px;

$iris-container-height: calc(
  100vh - #{$iris-header-height + $header-divider-height + $iris-footer-height}
);

$grid-width: 208rem;
$gutter-vertical: 1rem;
$gutter-horizontal: 4%;
$medium-min-width: 26rem;

$tipOffset: 20px;
