.cp-carousel-wrapper {
  background-color: #fff;
  height: 75vh;
  width: 58vw;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.28);
  .title {
    padding: 0rem 1.5rem;
    font-weight: 600;
    color: map-get($map: $text, $key: 600);
    font-size: $font-size-h3;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .mat-icon {
      margin-right: 8px;
      color: map-get($map: $primary, $key: 500);
    }
  }

  .content {
    margin-bottom: 24px;
    color: map-get($map: $text, $key: 500);
    display: block;
    height: 90%;
  }
}
