@mixin width-calculate($width, $class, $backdropColor) {
  @if ($class == "backdrop") {
    width: $width;
    @if ($backdropColor == "") {
      background: rgba(0, 0, 0, 0.3);
    } @else {
      background: $backdropColor;
    }
  } @else {
    width: max-content;
    position: absolute;
    top: 50%;
    left: $width/2;
    transform: translate(-50%, -50%);
  }
}

.labsandscans-overlay-pannel {
  position: absolute;
  bottom: 3rem;
  left: 3rem;
}

.view-summary-overlay-pannel {
  @include width-calculate(100%, "pannel", "");
  @include respond-to("big-screen") {
    @include width-calculate(100%, "pannel", "");
  }
}

.backdrop-width-65 {
  @include width-calculate(65%, "backdrop", "");
}

.panel-width-65 {
  @include width-calculate(65%, "panel", "");
}

.backdrop-width-50 {
  @include width-calculate(50%, "backdrop", "");
}

.panel-width-50 {
  @include width-calculate(50%, "panel", "");
}
