.cp-order {
  background-color: lighten($color: $cp-med, $amount: 47);

  &__link-icon {
    padding: 0.1em;
    text-decoration: underline;
    color: grey;
  }
}

.cp-med-light {
  background-color: lighten($color: $cp-med, $amount: 47);
}

a:not([href]):not([tabindex]) {
  padding: 0.1em;
  text-decoration: underline;
  color: grey;
}

a:not([href]):not([tabindex]):hover {
  cursor: pointer;
}

.card-body {
  padding: 1rem;
}

.cp-blood-light {
  background-color: lighten($color: $cp-blood, $amount: 55);
}

.fa-chevron-circle-down {
  color: $cp-primary;
  font-size: 20px;
  transition: 0.5s;
}
.arrow-up {
  .fa-chevron-circle-down {
    transform: rotate(180deg);
    color: $cp-secondary;
  }
}
.fa-chevron-circle-right,
.fa-chevron-circle-left {
  color: $cp-primary;
  font-size: 18px;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.order-icon-color {
    color: grey;
}
