/** Element declaration */

%cp-input-properties {
  border-radius: 0.25rem;
  border: 1px solid lightgrey;
  padding: 3px 5px !important;
  font-size: $cp-fs-btn !important;
  height: auto;
}

.cp-input {
  @extend %cp-input-properties;
}

.cp-textarea {
  @extend %cp-input-properties;
  resize: none !important;
  height: 5rem;
}

// .cp-badge {
//     padding: 0.25em 0.4em !important;
//     border-radius: 0.25em !important;
//     font-size: 0.7rem !important;
// }

.cp-w-small {
  width: 8rem;
}

.cp-inline {
  display: inline-block !important;
  vertical-align: top;
}

.cp-cursor-disabled {
  cursor: not-allowed !important;
}

.cp-cursor-pointer {
  cursor: pointer;
}

.cp-error {
  color: $cp-danger;
  display: float;
}

.cp-switch {
  position: relative;
  display: block;
  width: 30px;
  height: 17px;
  top: 2px;
  input {
    display: none;
    &:checked + .slider {
      background-color: $cp-info-p;
      &::before {
        -webkit-transform: translateX(13px);
        -ms-transform: translateX(13px);
        transform: translateX(13px);
      }
    }
    &:focus + .slider {
      box-shadow: 0 0 1px $cp-info-p;
    }
  }
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    &::before {
      position: absolute;
      content: "";
      height: 13px;
      width: 13px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }
  .round {
    border-radius: 17px;
    &::before {
      border-radius: 50%;
    }
  }
}

/** Hover colors */

.cp-hvr-brand-green {
  background: $cp-brand-green !important;
  color: $cp-white;
  &:hover {
    background: darken($color: $cp-brand-green, $amount: 15%) !important;
  }
}

.cp-hvr-brand-p {
  background: $cp-brand-p !important;
  color: $cp-white;
  &:hover {
    background: darken($color: $cp-brand-p, $amount: 15%) !important;
  }
}

.cp-hvr-primary {
  background: $cp-primary !important;
  color: $cp-white !important;
  &:hover {
    background: darken($color: $cp-primary, $amount: 15%) !important;
  }
}

.cp-hvr-secondary {
  background: $cp-secondary !important;
  color: $cp-white !important;
  &:hover {
    background: darken($color: $cp-secondary, $amount: 15%) !important;
  }
}

.cp-hvr-info-p {
  background: $cp-info-p !important;
  color: $cp-white;
  &:hover {
    background: darken($color: $cp-info-p, $amount: 15%) !important;
  }
}

.cp-hvr-info-s {
  background: $cp-info-s !important;
  color: $cp-white;
  &:hover {
    background: darken($color: $cp-info-s, $amount: 15%) !important;
  }
}

.cp-hvr-misc-p {
  background: $cp-misc-p !important;
  color: $cp-white;
  &:hover {
    background: darken($color: $cp-misc-p, $amount: 15%) !important;
  }
}

.cp-hvr-misc-s {
  background: $cp-misc-s !important;
  color: $cp-primary;
  &:hover {
    background: darken($color: $cp-misc-s, $amount: 15%) !important;
  }
}

.cp-hvr-misc-t {
  background: $cp-misc-t !important;
  color: $cp-primary;
  &:hover {
    background: darken($color: $cp-misc-t, $amount: 15%) !important;
  }
}

.cp-hvr-pend {
  background: $cp-pend !important;
  color: $cp-primary;
  &:hover {
    background: darken($color: $cp-pend, $amount: 15%) !important;
  }
}

.cp-hvr-grey {
  background: lighten($color: $cp-disabled, $amount: 15%) !important;
  color: $cp-black !important;
  &:hover {
    background: lighten($color: $cp-disabled, $amount: 5%) !important;
  }
}

.cp-hvr-red {
  background: lighten($color: $cp-danger, $amount: 15%) !important;
  color: white !important;
  &:hover {
    background: lighten($color: $cp-danger, $amount: 5%) !important;
  }
}

/** Background colors */

.cp-clr-brand-p {
  background: $cp-brand-green !important;
  color: $cp-white;
}

.cp-clr-brand-p {
  background: $cp-brand-p !important;
  color: $cp-white;
}

.cp-clr-primary {
  background: $cp-primary !important;
  color: $cp-white;
}

.cp-clr-secondary {
  background: $cp-secondary !important;
  color: $cp-white;
}

.cp-clr-info-p {
  background: $cp-info-p !important;
  color: $cp-white;
}

.cp-clr-info-s {
  background: $cp-info-s !important;
  color: $cp-white;
}

.cp-clr-misc-p {
  background: $cp-misc-p !important;
  color: $cp-white;
}

.cp-clr-misc-s {
  background: $cp-misc-s !important;
  color: $cp-primary;
}

.cp-clr-misc-t {
  background: $cp-misc-t !important;
  color: $cp-primary;
}

.cp-clr-danger {
  background: $cp-danger !important;
  color: $cp-white !important;
}

.cp-clr-dark {
  background: $cp-dark !important;
  color: $cp-white !important;
}

.cp-hvr-pend {
  background: $cp-pend !important;
  color: $cp-primary;
}

.cp-clr-comfort {
  background: $cp-comfort !important;
  color: white;
}

.cp-clr-disable {
  background: $cp-disabled !important;
}

/** Font colors */

.cp-font-brand-p {
  color: $cp-brand-green !important;
}

.cp-font-brand-s {
  color: $cp-brand-p !important;
}

.cp-font-primary {
  color: $cp-primary !important;
}

.cp-font-secondary {
  color: $cp-secondary !important;
}

.cp-font-info-p {
  color: $cp-info-p !important;
}

.cp-font-info-s {
  color: $cp-info-s !important;
}

.cp-font-misc-p {
  color: $cp-misc-p !important;
}

.cp-font-misc-s {
  color: $cp-misc-s !important;
}

.cp-font-danger {
  color: $cp-danger !important;
}

.cp-font-pend {
  color: $cp-pend !important;
}

.cp-font-white {
  color: white !important;
}

.cp-font-link {
  color: lighten($cp-i-link, 30%) !important;
  &:hover {
    color: $cp-i-link !important;
    text-decoration: underline;
  }
}

/** Outline colors */

.cp-outline-brand-p {
  border: 1px solid $cp-brand-p !important;
  &:not(:disabled):not(.disabled).active {
    background-color: $cp-brand-p;
  }
  &:focus {
    outline: none !important;
  }
}

.cp-outline-primary {
  border: 1px solid $cp-primary !important;
  &:not(:disabled):not(.disabled).active {
    background-color: $cp-primary;
  }
  &:focus {
    outline: none !important;
  }
}

.cp-outline-secondary {
  border: 1px solid $cp-secondary !important;
  &:not(:disabled):not(.disabled).active {
    background-color: $cp-secondary;
  }
  &:focus {
    outline: none !important;
  }
}

.cp-outline-info-p {
  border: 1px solid $cp-info-p !important;
  &:not(:disabled):not(.disabled).active {
    background-color: $cp-info-p;
  }
  &:focus {
    outline: none !important;
  }
}

.cp-outline-info-s {
  border: 1px solid $cp-info-s !important;
  &:not(:disabled):not(.disabled).active {
    background-color: $cp-info-s;
  }
  &:focus {
    outline: none !important;
  }
}

.cp-outline-misc-p {
  border: 1px solid $cp-misc-p !important;
  &:not(:disabled):not(.disabled).active {
    background-color: $cp-misc-p;
  }
  &:focus {
    outline: none !important;
  }
}

.cp-outline-misc-s {
  border: 1px solid $cp-misc-s !important;
  &:not(:disabled):not(.disabled).active {
    background-color: $cp-misc-s;
  }
  &:focus {
    outline: none !important;
  }
}

.cp-btn-white {
  color: $cp-dark;
  background-color: $cp-white !important;
  background-image: none;
  border-color: $cp-disabled !important;
  box-shadow: none !important;
  cursor: pointer;
  &:not(:disabled):not(.disabled).active {
    color: $cp-white;
    background-color: $cp-dark !important;
    border-color: $cp-dark !important;
  }
}

.cp-outline-primary {
  color: $cp-white;
  background-color: $cp-white;
  background-image: none;
  border-color: $cp-primary !important;
  box-shadow: none !important;
  cursor: pointer;
  &:not(:disabled):not(.disabled).active {
    background-color: $cp-misc-s;
  }
  &:focus {
    outline: none !important;
  }
}

.cp-outline-pend {
  border: 1px solid $cp-pend !important;
  &:not(:disabled):not(.disabled).active {
    background-color: $cp-pend;
  }
  &:focus {
    outline: none !important;
  }
}

.cp-btn:disabled,
.cp-input:disabled,
.cp-input-group:disabled {
  // border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
  cursor: not-allowed !important;
}

/** Cheat declaration */

.clear-height {
  clear: both;
  float: none;
}

.cp-order-action-icon {
  font-size: 20px;
  cursor: pointer;
  color: $cp-disabled;
}

.cp-action-yellow {
  background: $cp-m-yellow !important;
  color: white !important;
}

.cp-action-green {
  background: $cp-m-green !important;
  color: white !important;
}

.cp-action-grey {
  background: $cp-grey !important;
  color: white !important;
}

.cp-action-grey-darker {
  background: $cp-grey-dark !important;
  color: white !important;
}

.cp-green-text {
  color: $cp-m-green;
}

.icon-button {
  border: none;
  background-color: transparent;
}

.cp-heading-primary {
  color: $cp-primary;
  font-size: 1.5rem;
}

.text-20 {
  font-size: 20px;
}

.admin-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.error {
  color: $cp-m-red;
  font-size: 0.9rem;
}

.disabled-color {
  color: $cp-grey-dark;
}
