// Mar infusion tooltip
.cdk-overlay-pane.mar-infusion-tooltip {
  transform: translateY(-100%);
}

[class*="cp-tooltip"] {
  &::before {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    background-color: white;
    transform: rotate(45deg);
  }

  &[class*="top"] {
    &::before {
      bottom: -6px;
      box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.1);
    }
  }

  &[class*="bottom"] {
    &::before {
      top: -6px;
      box-shadow: -2px -2px 2px 0px rgba(0, 0, 0, 0.1);
    }
  }

  &[class*="left"] {
    &::before {
      right: -6px;
      box-shadow: 2px -1px 4px -1px rgba(0, 0, 0, 0.2);
    }
  }

  &[class*="right"] {
    &::before {
      left: -6px;
      box-shadow: -1px 2px 2px 0px rgba(0, 0, 0, 0.15);
    }
  }

  &[class*="top"],
  &[class*="bottom"] {
    &[class*="start"] {
      &::before {
        left: $tipOffset;
        transform: translate(-50%, 0) rotate(45deg);
      }
    }

    &[class*="center"] {
      &::before {
        left: calc(50% - 6px);
      }
    }

    &[class*="end"] {
      &::before {
        right: $tipOffset;
        transform: translate(50%, 0) rotate(45deg);
      }
    }
  }

  &[class*="left"],
  &[class*="right"] {
    &[class*="start"] {
      &::before {
        top: $tipOffset;
        transform: translate(0px, -50%) rotate(45deg);
      }
    }

    &[class*="center"] {
      &::before {
        top: calc(50% - 6px);
      }
    }

    &[class*="end"] {
      &::before {
        bottom: $tipOffset;
        transform: translate(0px, 50%) rotate(45deg);
      }
    }
  }
}

.popover-cont {
  display: flex;
  flex-direction: column;

  .tp-head {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: map-get($text, 600);
  }

  .tp-body {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: map-get($text, 300);
  }
}
