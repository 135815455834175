// Order Type Icon
.ot-icon {
    &--blood {
        color: $cp-blood;
    }

    &--comm {
        color: $cp-comm;
    }

    &--diet {
        color: $cp-diet;
    }

    &--lab {
        color: $cp-lab;
    }

    &--med {
        color: $cp-med;
    }

    &--procedure {
        color: $cp-procedure;
    }
    
    &--vent {
        color: $cp-vent;
    }
}
