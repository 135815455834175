/*======================================
  						Primary 
=======================================*/

$primary: (
  50: #e7f4f6,
  100: #e5f2f2,
  200: #9ad1d8,
  300: #71bec8,
  400: #52b0bd,
  500: #34a2b1,
  600: #2f9aaa,
  700: #2790a1,
  800: #218698,
  900: #157588,
  A100: #bdf3ff,
  A200: #8aeaff,
  A400: #57e1ff,
  A700: #3ddcff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/*======================================
  					New	primary
=======================================*/

$primary-new: (
  100: #e5f2f2,
  200: #b1e2e2,
  300: #7fcece,
  400: #34a2b1,
  500: #2c8a97,
  600: #22727c,
  700: #0d383e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

/*======================================
  						Text
=======================================*/

$text: (
  100: #ffffff,
  200: #ccd7eb,
  300: #8b94b3,
  400: #677797,
  500: #465065,
  600: #2b2a3a,
);

/*======================================
  						Background
=======================================*/

$bg: (
  000: #ffffff,
  100: #f6f7f9,
  200: #eeeff4,
  300: #e2e8f4,
  350: #e5e5e5,
  400: #cdd7eb,
  500: #8b94b3,
  600: #596782,
  700: #384359,
  800: #2b2a3a,
);

/*======================================
  							Blue
=======================================*/

$blue: (
  100: #f4f7fe,
  200: #e0edff,
  300: #b5d2fe,
  400: #488af8,
  500: #3271da,
  600: #19499b,
  700: #0d2d64,
);

/*======================================
  							Cyan
=======================================*/

$cyan: (
  100: #effbff,
  200: #b1ecff,
  300: #4ecff8,
  400: #21a5ce,
  500: #1a8caf,
  600: #14708c,
  700: #0e4c5f,
);

/*======================================
  							Orange
=======================================*/

$orange: (
  100: #fff0e0,
  200: #fddbb8,
  300: #ffbf81,
  400: #ff7f00,
  500: #cc5d01,
  600: #992e04,
  700: #641f03,
);

/*======================================
  							Yellow
=======================================*/

$yellow: (
  100: #fffde8,
  200: #fff59c,
  300: #f8e753,
  400: #fac02e,
  500: #d19113,
  600: #6e5014,
  700: #43300b,
);

/*======================================
  							Error-color
=======================================*/

$error: (
  100: #fef2f2,
  200: #fee0e2,
  300: #ffbdbe,
  400: #eb4049,
  500: #bf2638,
  600: #811a2d,
);

/*======================================
  							Green
=======================================*/

$green: (
  100: #eef9f5,
  200: #daefe8,
  300: #8ee6c0,
  400: #28a677,
  500: #0e875c,
  600: #004c3f,
  700: #022f27,
);

/*======================================
  							Alpha-grey
=======================================*/

$alpha-grey: (
  100: rgba(42, 42, 58, 0.1),
  200: rgba(42, 42, 58, 0.15),
  300: rgba(42, 42, 58, 0.3),
  400: rgba(42, 42, 58, 0.4),
  500: rgba(42, 42, 58, 0.5),
  600: rgba(42, 42, 58, 0.8),
);

/*======================================
  							Cool-grey
=======================================*/

$cool-grey: (
  100: #f5f7f7,
  200: #dee1e6,
  300: #9aa0a6,
  400: #767a7e,
  500: #4a4c4f,
);

/*======================================
  							Variables
=======================================*/

$cp-white: #ffffff;
$cp-black: #000000;

/*======================================
  							Tables State
=======================================*/

$table-danger: #f5c6cb;
$table-warning: #ffeeba;
