.cdk-overlay-pane > .mat-menu-panel > .mat-menu-content > .mat-menu-item {
  display: flex;
  align-items: center;
  height: 35px !important;
}

.cdk-overlay-pane > .mat-menu-panel > .mat-menu-content > .user-status-item >,
.site-header .availability-status > .user-status > {
  .red-dot,
  .green-dot,
  .black-dot {
    border-radius: 50%;
    height: 10px;
    width: 10px;
    margin: 0 8px;
    align-self: center;
  }

  .red-dot {
    background-color: map-get($map: $error, $key: 400);
  }

  .green-dot {
    background-color: map-get($map: $green, $key: 400);
  }

  .black-dot {
    background-color: map-get($map: $bg, $key: 800);
  }

  .red {
    color: map-get($map: $error, $key: 400);
  }

  .green {
    color: map-get($map: $green, $key: 400);
  }

  .black {
    color: map-get($map: $bg, $key: 800);
  }
}

.mat-select-panel {
  margin: 44px 0 0 8px !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
